<template>
  <v-row align="center" justify="center">
    <v-card v-if="dialog" class="elevation-16" width="900">
      <v-row
        style="height: 476px"
        v-if="loading"
        align="center"
        justify="center"
      >
        <v-progress-circular indeterminate color="red"></v-progress-circular>
      </v-row>
      <v-form v-else v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <h2>Zadejte údaje, se kterými Vám vygenerujeme certifikát</h2>
              <v-text-field
                v-model="email"
                :counter="10"
                label="E-mail"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field
                v-model="title"
                :counter="10"
                label="Titul"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field
                v-model="firstname"
                :counter="10"
                label="Křestní jméno"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field
                v-model="lastname"
                :counter="10"
                label="Příjmení"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field
                v-model="company"
                :counter="10"
                label="Firma"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-btn
              class="replay-button"
              @click="goNext()"
              x-large
              color="success"
            >
              Vygenerovat certifikát
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
    <v-card v-else class="elevation-16" width="900">
      <embed :src="link" width="900px" height="700px" />
      <v-card-actions align="center">
        <v-row align="center" justify="center">
          <v-btn x-large @click="close" color="success"> <v-icon dark left>mdi-close-circle-outline</v-icon>Zavřít</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>
import api from "../../../api/api";
import apils from "../../../api/ls";
import EventBus from "../../../event-bus";

export default {
  props: {
    user: Object,
    practiceId: Number,
  },

  data() {
    return {
      rating: 0,
      dialog: true,
      text: null,
      link: null,
      email: null,
      firstname: null,
      lastname: null,
      title: null,
      company: null,
      loading: true,
    };
  },
  methods: {
    close(){
EventBus.$emit("SWITCH_PRACTICE", "dashboard");
    },
    goNext() {
      api
        .post("/practice/generateCert", {
          user_id: this.user.email,
          email: this.email,
          firstname: this.firstname,
          lastname: this.lastname,
          title: this.title,
          company: this.company,
          name: this.user.name,
          course_item_id: this.practiceId,
          result: 100,
        })
        .then((response) => {
          this.link = response.data;
          this.dialog = false;
        });
    },
  },
  mounted() {
    apils.get("af_restServer/v1/af_user").then((response) => {
      this.email = response.data.user_email;
      this.firstname = response.data.name;
      this.lastname = response.data.surname;
      this.title = response.data.title;
      this.company = response.data.company_name;
      this.loading = false;
    });

    console.log(this.user);
  },
};
</script>

<style scoped>
h2 {
  width: 100%;
  text-align: center;
  padding: 20px;
}
</style>

