import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueRouter from "vue-router";
import routes from "./routes";
import { store } from "./store/store";
import api from "./api/api";
import browserDetect from "vue-browser-detect-plugin";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";

require("@/store/subscriber");
Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.config.silent = true
Vue.use(browserDetect);
export const userToken = "e4bd64dfac85002b9e4375a5338782d4";
const router = new VueRouter({ mode: "history", routes });

Vue.filter("time", function(value) {
  if (!value) return "";
  var sec_num = parseInt(value, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return minutes + ":" + seconds;
});

Vue.filter("scoreIcon", function(value) {
  let icon;
  if (value > 85) {
    icon = "mdi-emoticon-excited-outline";
  } else if (value <= 84 && value > 74) {
    icon = "mdi-emoticon-happy-outline";
  } else {
    icon = "mdi-emoticon-sad-outline";
  }

  return icon;
});

Vue.filter("scoreColor", function(value) {
  let color;
  if (value > 85) {
    color = "green darken-2";
  } else if (value <= 84 && value > 74) {
    color = "orange darken-2";
  } else {
    color = "red";
  }

  return color;
});

Vue.filter("itemName", function(value) {
  api.get("practice/getItem/" + value).then((response) => {
    return response.name;
  });
});

store.dispatch("auth/attemptToken", localStorage.getItem("token")).then(() => {
  new Vue({
    vuetify,
    store,
    render: (h) => h(App),
    router,
  }).$mount("#app");
});
if (process.env.VUE_APP_ENV == "Production") {
  Sentry.init({
    environment: process.env.VUE_APP_ENV,
    dsn:
      "https://1dea5f5db9ed4e53b2b026ea89732bb3@o481698.ingest.sentry.io/5530755",
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
      }),
      new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}
