import axios from 'axios';

function getHostName() {
    var host = "https://vyvojls.cz/";
    var localhost = "http://localhost/e-learning-api/";
    if(location.host === "localhost:8080") {
        host = localhost;
    }

    return host;
}

export default axios.create({
    baseURL: getHostName() + `api/v1/`,
    headers: {
        'Content-Type': 'application/json',
      
    },
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    withCredentials: false
});