<template>
  <div>
    <v-card v-show="showResults" class="mx-auto" width="700" max-width="800">
      <v-card-text align="center">
        <v-icon class="result-icon" :color="this.score | scoreColor">{{
          this.score | scoreIcon
        }}</v-icon>
        <div>Vaše výsledné skore je:</div>
        <p class="display-1 text--primary">{{ Math.round(this.score) }} %</p>
        <p>{{ text }}</p>
      </v-card-text>
      <v-card-actions align="center">
        <v-row
          v-show="this.score == 100"
          align="center"
          justify="center"
        >
          <v-btn x-large color="success" @click="finishTest">Pokračovat</v-btn>
        </v-row>
        <v-row v-show="this.score > 74 && this.score < 100" align="center" justify="center">
          
          <v-btn color="blue" @click="repeat">
            <v-icon dark left>mdi-reload</v-icon>Zkusit znovu cvičení</v-btn
          >
          <v-btn x-large color="success" @click="finishTest">Pokračovat</v-btn>
          <v-btn
            v-if="hasHelp"
            color="red darken-2 "
            @click="
              showResults = false;
              showHelp = true;
            "
          >
            <v-icon dark left>mdi-help-circle-outline</v-icon>Zobrazit
            nápovědu</v-btn
          >
        </v-row>
        <v-row v-show="this.score <= 74" align="center" justify="center">
          <v-btn v-if="hasHelp" color="blue" @click="goToVideo">
            <v-icon dark left>mdi-play</v-icon>Znovu přehrát video</v-btn
          >
          <v-btn x-large color="success" @click="repeat">
            <v-icon dark left>mdi-reload</v-icon>Zkusit znovu</v-btn
          >
          <v-btn
            v-if="hasHelp"
            color="red darken-2 "
            @click="
              showResults = false;
              showHelp = true;
            "
          >
            <v-icon dark left>mdi-help-circle-outline</v-icon>Zobrazit
            nápovědu</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card v-show="showHelp" class="mx-auto" max-width="800">
      <v-card-text align="center">
        <h2>{{ helpName }}</h2>
        <p v-html="helpText">
          {{ helpText }}
        </p>
      </v-card-text>
      <v-card-actions align="center">
        <v-row align="center" justify="center">
          <v-btn
            v-if="hasHelp"
            x-large
            color="amber"
            @click="
              showHelp = false;
              showResults = true;
            "
            >Zavřít nápovědu</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import EventBus from "../../event-bus";
import api from "../../api/api";
import help from "./help.json";

export default {
  props: {
    score: Number,
    userId: String,
    itemId: Number,
    settings: Object,
  },
  data() {
    return {
      showResults: true,
      text: null,
      help: help,
      hasHelp: false,
      showHelp: false,
      backVideo: null,
      helpName: null,
      helpText: null,
    };
  },
  mounted() {
    if (this.score < 100) {
      if (this.help["help"][this.itemId] !== undefined) {
        this.hasHelp = true;
        this.helpName = this.help["help"][this.itemId]["text"]["name"];
        this.helpText = this.help["help"][this.itemId]["text"]["text"];
        api
          .post("/practice/getVideoSettings", {
            practice_id: this.help["help"][this.itemId]["text"]["videoId"],
          })
          .then((response) => {
            this.backVideo = response.data;
          });
      }
    }
    api
      .post("/practice/saveResult", {
        user_id: this.userId,
        course_item_id: this.itemId,
        result: this.score,
      })
      .then(() => {
        if (this.itemId == 30) {
          if (this.score < 75) {
            this.text =
              "Je nám líto, k úspěšnému absolvování kurzu je potřeba alespoň 75%";
          } else if (this.score > 74 && this.score < 80) {
            this.text =
              "Gratulujeme k úspěšnému absolvování kurzu základů první pomoci!";
          } else if (this.score > 79 && this.score < 90) {
            this.text =
              "Gratulujeme, je vidět, že v problematice první pomoci jste jako ryba ve vodě, dobrá práce!";
          } else if (this.score > 89) {
            this.text =
              "Gratulujeme, tohle je úžasný výsledek! Vaše okolí může být v klidu, první pomoc zvládáte na jedničku!";
          }
        }
      });
  },
  methods: {
    finishTest() {
      this.showResults = false;
      if (this.settings.goToVideo == true) {
        this.$parent.goToVideo();
      } else if (this.settings.goToPractice) {
        EventBus.$emit("SWITCH_PRACTICE", "draganddroporder", 23);
      } else {
        this.toggleDrawer(true);
        this.goToDashboard();
      }
    },
    goToVideo() {
      this.$parent.$parent.openPractice(
        "video",
        this.help["help"][this.itemId]["text"]["videoId"],
        this.backVideo.path,
        this.backVideo.settings,
        true
      );
    },
    repeat() {
      this.$parent.reset(this.itemId);
    },
    toggleDrawer(toggle) {
      EventBus.$emit("TOGGLE_DRAWER", toggle);
    },
    goToDashboard() {
      EventBus.$emit("SWITCH_PRACTICE", "dashboard");
    },
  },
};
</script>
<style scoped>
button {
  margin-left: 10px;
  color: white !important;
}
button:last-of-type {
  margin-right: 10px;
}
</style>