import apils from "../api/ls";
import api from "../api/api";
import * as Sentry from "@sentry/browser";

export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, data) {
      state.user = data;
    },
  },
  actions: {
    async signIn({ dispatch }, credentials) {
      let response = await apils.post("jwt-auth/v1/token", credentials);
      if (response.data.statusCode == 200) {
        if (response.data.data.elearning_till) {
          Sentry.setUser({ email: response.data.data.email });
          Sentry.setTag(
            "browser.resolution",
            window.screen.availWidth + "x" + window.screen.availHeight
          );
          dispatch("attempt", response.data.data);
        } else {
          throw ("noElearning");
        }
      } else if (response.data.statusCode == 403)
        throw ("403");
    },

    async attempt({ commit }, data) {
      await commit("SET_TOKEN", data.token);
      await commit("SET_USER", data);
    },

    async attemptToken({ commit }, token) {
      await commit("SET_TOKEN", token);
    },
    signOut({ commit }) {
      return api.post("users/logout").then(() => {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      });
    },
  },
  getters: {
    authenticated(state) {
      return state.user && state.token;
    },
    user(state) {
      return state.user;
    },
    token() {
      return this.token;
    },
  },
};
