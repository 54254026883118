<template>
  <v-app id="inspire">
    <div v-if="authenticated">
      <SideBar :drawer="true" />
    </div>
    <div v-else>
      <SideBarPublic />
    </div>

    <v-main>
      <v-container class="fill-height" v-if="authenticated">
        <v-row>
          <router-view />
          <Notepad v-show="!isTutorial" :data="{ userId: user.email }" />
        </v-row>
      </v-container>
      <v-container fluid v-else>
        <v-responsive :aspect-ratio="16 / 9">
          <v-row justify="center" align="center" style="height: 600px">
            <Login />
          </v-row>
        </v-responsive>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import SideBar from "./components/Main/SideBar";
import SideBarPublic from "./components/Main/SideBarPublic";
import Login from "./components/Main/LoginForm";
import { mapGetters } from "vuex";
import Notepad from "./components/Tools/Notepad";

export default {
  name: "App",
  isLogged: false,
  test: null,
  authenticated: false,
  darkTheme: false,
  isTutorial: false,
  hasLearning: false,
  components: {
    SideBar,
    SideBarPublic,
    Login,
    Notepad,
  },
  props: {
    source: String,
  },
  created() {
    this.$vuetify.theme.dark = false;

    if (this.$route.name === "tutorial") {
      this.isTutorial = true;
    } else {
      this.isTutorial = false;
    }
  },
  watch: {
    currentRouteName: function (val) {
      if (val === "tutorial") {
        this.isTutorial = true;
      } else {
        this.isTutorial = false;
      }
    },
  },
  methods: {},

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
};
</script>
<style>
.a-sub-menu {
  margin-left: 20px;
}
</style>