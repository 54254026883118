<template>
  <div>
    <v-row justify="left">
      <v-col class="tutorial">
        <h2>Průvodce</h2>
        <p>
          Vítejte v prostředí našeho e-kurzu, který vznikl v období koronakrize jako distanční forma tréninku první pomoci. Netvrdíme, že dokáže
          nahradit prezenční kurz, ve kterém máte možnost si vše vyzkoušet prakticky, ale věříme, že Vám dobře poslouží k pochopení základů první pomoci.</p>
        <p>Kurz je tvořen výukovými videi, po každé kapitole Vás čeká krátké cvičení, které je potřeba splnit alespoň na 75%. Pokud se Vám to nepodaří na první pokus, nezoufejte, test můžete zopakovat, znovu přehrát výukové video nebo si zobrazit nápovědu. Splnění testu je podmínkou pro odemčení další kapitoly.</p>
        <p>Kdykoli v průběhu kurzu si můžete vytvořit záložku stisknutím této ikony:
          <Bookmark
            :data="bookmarkData"
            :right="false"
            :bottom="false"
            :fixed="false"
            :isTutorial="true"
          />
          <br />
          <br />Slouží hlavně k uložení důležitých míst ve videích. Upozorňujeme, že při použití záložky ve cvičení se neuloží rozpracovaný postup.</p>
        <p>Druhou ikonu, kterou najdete v prostředí e-kurzu je poznámkový blok:
          <Notepad
            :data="{ userId: this.user.email }"
            :right="false"
            :bottom="false"
            :fixed="false"
            :isTutorial="true"
          />
          <br />Vámi vytvořené poznámky můžete následně zobrazit kdykoli a to i během testů, včetně závěrečného.</p>
        <p><strong>Ke splnění závěrečného testu je potřeba dosáhnout úspěšnosti 75%.</strong> Po absolvování si vygenerujete certifikát s Vaším jménem.</p>                  
        <p>Položka menu „<strong><a href="/dotazy">Dotazy</a></strong>“ složí jako rozcestník pro technické i odborné dotazy. Odpověď obdržíte do e-mailové schránky.</p>
        <p>Na konci kurzu se Vám zobrazí krátký formulář zpětné vazby, budeme vděční za jeho vyplnění.
          <br />
          <br />Tým Life Support
        </p>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-btn
        class="replay-button"
        v-show="showButton"
        x-large
        color="success"
        @click="goToVideo"
      >
        Pokračovat na video
        <v-icon dark right>mdi-play</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import Bookmark from "../Tools/Bookmark";
import Notepad from "../Tools/Notepad";
import api from "../../api/api";
import { mapGetters } from "vuex";

export default {
  props: {
    showButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Bookmark,
    Notepad,
  },
  data() {
    return {};
  },
  mounted() {
    api.post("/practice/saveResult", {
      user_id: this.user.email,
      course_item_id: 31,
      result: 100,
    });
  },
  methods: {
    goToVideo() {
      this.$router.push({
        name: "practice",
        params: {
          actual: "video",
          practiceId: 2,
          path: "02_Uvod.mp4",
          settings: {},
          videoStartTime: null,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      darkTheme: "darkTheme/darkTheme",
    }),
    bookmarkData() {
      return { userId: this.user.email, practiceId: 0, videoTime: 1 };
    },
  },
};
</script>
<style scoped>
.row {
  padding-left: 20px;
}
.tutorial {
  width: 1000px;
}
</style>