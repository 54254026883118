import axios from 'axios'
import { store } from '../store/store'

function getHeaders () {
  let headers
  if (store.getters['auth/authenticated']) {
    headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + store.getters['auth/authenticated']
    }
  } else {
    headers = {
      'Content-Type': 'application/json'
    }
  }

  return headers
}

export default axios.create({
  baseURL: `https://lifesupport.cz/wp-json/`,
  headers: getHeaders(),
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  withCredentials: false
})
