<template>
  <v-container v-if="this.score !== null">
    <Results
      :userId="this.userId"
      :itemId="this.practiceId"
      :score="this.score"
      :settings="this.resultSettings"
    />
  </v-container>
  <v-container v-else>
    <Bookmark :data="bookmarkData" />
    <v-overlay :absolute="true" :value="overlay"> </v-overlay>
    <v-card v-show="dialog" class="mx-auto" max-width="500">
      <v-card-text align="center">
        <h2>{{ practiceName }}</h2>
        {{ practiceInfo }}
      </v-card-text>
      <v-card-actions align="center">
        <v-row align="center" justify="center">
          <v-btn
            @click="
              dialog = false;
              overlay = false;
            "
            x-large
            color="success"
            >Jdu na to</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
    <v-col>
      <v-row>
        <v-col cols="8">
          <v-row
            :class="completeRow[rowName] ? 'completeRow' : ''"
            v-for="(row, rowName, rowIndex) in words"
            :key="rowIndex"
          >
            <span class="font-weight-black">{{ rowName + 1 }}</span>
            <v-text-field
              @keyup="onKeyup"
              :id="String((rowName + 1) * 100 + colName)"
              v-for="(col, colName, colIndex) in row"
              :key="colIndex"
              :class="col == '' ? 'normal hidden' : 'normal'"
              :disabled="col == '' ? true : false"
              :background-color="colName == 6 ? 'blue lighten-4' : ''"
              maxlength="1"
              v-model="crosswords[rowName][colName]"
              :ref="String((rowName + 1) * 100 + colName)"
              outlined
              style="text-transform: uppercase"
            ></v-text-field>
          </v-row>
        </v-col>

        <v-col cols="4">
          <v-col cols="12" justify="center" align="center">
            <div class="base-timer">
              <svg
                class="base-timer__svg"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g class="base-timer__circle">
                  <circle
                    class="base-timer__path-elapsed"
                    cx="50"
                    cy="50"
                    r="45"
                  />
                  <path
                    :stroke-dasharray="circleDasharray"
                    class="base-timer__path-remaining"
                    :class="remainingPathColor"
                    d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
                  />
                </g>
              </svg>
              <span class="base-timer__label">{{ formattedTimeLeft }}</span>
            </div>
          </v-col>
          <ol class="q subtitle-1" type="1">
            <li>
              Úraz neboli
              <b>P</b>..... mozku
            </li>
            <li>
              Vysoká nebo nízká
              <b>T</b>.......
            </li>
            <li>
              Vysoká nebo nízká hladina
              <b>C</b>.... v krvi
            </li>
            <li>Cévní mozková příhoda neboli <b>M</b>......</li>
            <li>
              Vysoký nebo nízký krevní ...
              <b>K</b>
            </li>
            <li>
              Otrava léky, alkoholem nebo
              <b>D</b>......
            </li>
            <li>
              Nedostatek ....
              <b>Í</b>.. v krvi
            </li>
            <li>
              Lék se od
              <b>J</b>... liší pouze svojí dávkou.
            </li>
            <li>
              Zástava
              <b>S</b>......
            </li>
          </ol>
        </v-col>
      </v-row>
      <v-row v-if="button" align="center" justify="center">
        <v-btn @click="score = 100" x-large color="success">Vyhodnotit</v-btn>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import Results from "../../Tools/Results";
import EventBus from "../../../event-bus";
import Bookmark from "../../Tools/Bookmark";
import api from "../../../api/api";

const FULL_DASH_ARRAY = 283;
const WARNING_THRESHOLD = 10;
const ALERT_THRESHOLD = 5;

const COLOR_CODES = {
  info: {
    color: "green",
  },
  warning: {
    color: "orange",
    threshold: WARNING_THRESHOLD,
  },
  alert: {
    color: "red",
    threshold: ALERT_THRESHOLD,
  },
};
export default {
  components: {
    Results,
    Bookmark,
  },
  props: {
    userId: String,
    practiceId: Number,
  },
  data() {
    return {
      resultSettings: {
        goToVideo: true,
      },
      button: false,
      overlay: true,
      completeRow: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      crosswords: [
        ["", "", "P", "", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "T", "", "", "", "", "", ""],
        ["", "", "", "C", "", "", "", "", "", "", "", ""],
        ["M", "", "", "", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "K", "", "", "", ""],
        ["", "", "", "D", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "Í", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "J", "", "", "", "", ""],
        ["", "", "", "S", "", "", "", "", "", "", "", ""],
      ],
      words: [
        ["", "", "P", "O", "R", "A", "N", "Ě", "N", "Í", "", ""],
        ["", "", "", "", "", "T", "E", "P", "L", "O", "T", "A"],
        ["", "", "", "C", "U", "K", "R", "U", "", "", "", ""],
        ["M", "R", "T", "V", "I", "C", "E", "", "", "", "", ""],
        ["", "", "", "", "T", "L", "A", "K", "", "", "", ""],
        ["", "", "", "D", "R", "O", "G", "A", "M", "I", "", ""],
        ["K", "Y", "S", "L", "Í", "K", "U", "", "", "", "", ""],
        ["", "", "", "", "", "", "J", "E", "D", "U", "", ""],
        ["", "", "", "S", "R", "D", "C", "E", "", "", "", ""],
      ],
      timePassed: 0,
      results: [],
      drawer: null,
      score: null,
      timeLimit: 180,
      timerInterval: null,
      timer: 180,
      compress: null,
      lastCompress: null,
      count: null,
      testStarted: false,
      interval: false,
      buttonText: "Spustit test",
      buttonIcon: "mdi-check",
      loader: null,
      loading2: false,
      dialog: true,
      practiceName: null,
      practiceInfo: null,
    };
  },
  computed: {
    bookmarkData() {
      return {
        userId: this.userId,
        practiceId: this.practiceId,
        videoTime: null,
      };
    },
    circleDasharray() {
      return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
    },

    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },

    timeLeft() {
      return this.timeLimit - this.timePassed;
    },

    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.timeLimit;
      return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction);
    },

    remainingPathColor() {
      const { alert, warning, info } = COLOR_CODES;

      if (this.timeLeft <= alert.threshold) {
        return alert.color;
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      }
    },
  },

  watch: {
    timeLeft(newValue) {
      this.timer = newValue;

      if (newValue === 0) {
        this.onTimesUp();
      }
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
    crosswords() {
      if (!this.testStarted) {
        this.testStarted = true;
        this.startTimer();
      }

      this.toggleDrawer(false);
      let i = 0;
      let score = 0;
      for (let row of this.words) {
        let newRow = row.map((item) => item.toUpperCase());
        let newCrosswords = this.crosswords[i].map((item) =>
          item.toUpperCase()
        );
        if (JSON.stringify(newRow) === JSON.stringify(newCrosswords)) {
          score++;
          this.highlightRow(i);
        }
        i++;
      }
      if (score === 9) {
        this.button = true;
      }
    },
  },

  mounted() {
    api
      .post("/practice/getPracticeInfo", { practiceId: this.practiceId })
      .then((response) => {
        this.practiceName = response.data.name;
        this.practiceInfo = response.data.describe;
      });

    this.toggleDrawer(false);
  },

  methods: {
    goToVideo() {
      this.$parent.openPractice(
        "video",
        17,
        "12_Zaklon_hlavy.mp4",
        '{"relatedVideos": false, "goToPractice": "listorder", "practiceId": "25"}',
        true
      );
    },
    reset(practiceId) {
      this.$parent.switchPractice("crossword", practiceId);
    },
    onTimesUp() {
      clearInterval(this.timerInterval);
      this.getScore();
    },

    highlightRow(row) {
      this.completeRow[row] = true;
    },
    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    recordClick() {
      if (this.testStarted) {
        this.results.push(this.timer);
        this.count = this.count + 1;
      }
    },
    getScore() {
      let i = 0;
      let score = 0;
      for (let row of this.words) {
        let newRow = row.map((item) => item.toUpperCase());
        let newCrosswords = this.crosswords[i].map((item) =>
          item.toUpperCase()
        );
        if (JSON.stringify(newRow) === JSON.stringify(newCrosswords)) {
          score++;
        }
        i++;
      }

      this.score = Math.round((score / 9) * 100);
    },
    toggleDrawer(toggle) {
      EventBus.$emit("TOGGLE_DRAWER", toggle);
    },
    onKeyup(event) {
      let nextRel;
      let precisousRel;
      nextRel = Number(event.target.id);
      nextRel++;
      nextRel = String(nextRel);
      precisousRel = Number(event.target.id);
      precisousRel--;
      precisousRel = String(precisousRel);
      const input = document.getElementById(nextRel);
      let inp = String.fromCharCode(event.keyCode);
      if (event.keyCode == 8) {
        return document.getElementById(precisousRel).focus();
      }
      if (/[a-zA-Z0-9]/.test(inp)) {
        // event.target.value = event.target.value.replace(/[^a-z]/g, '');
        return input.focus();
      } else {
        return;
      }
    },
  },
};
</script>
<style>
.completeRow input {
  color: #66bb6a !important;
}
.normal input {
  width: 48px;
  float: left;
  text-align: center;
  font-size: 26px;
  padding: 10px;
  color: transparent;
  text-shadow: 0 0 0 black;
}
.v-input__slot {
  padding: 0px !important;
}

.v-input__control {
  margin-right: 6px !important;
}
.row {
  width: 100%;
}

.hidden {
  opacity: 0;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin: 0px !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0px !important;
}
.v-text-field__details {
  display: none;
}
.font-weight-black {
  font-size: 20px;
  padding-top: 15px;
  margin-right: 50px;
}
.q {
  margin-top: 50px;
}
</style>
<style scoped lang="scss">
.base-timer {
  position: relative;
  width: 150px;
  height: 150px;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }

  &__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: rgb(65, 184, 131);
    }

    &.orange {
      color: orange;
    }

    &.red {
      color: red;
    }
  }

  &__label {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
  }
}
</style>
