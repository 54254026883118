<template>
  <div>
    <v-btn
      title="Otevřít poznámkový blok"
      @click="openDialog"
      v-bind:class="{ 'mx-2': !isTutorial, 'tutorial-notepad': isTutorial }"
      dark
      color="warning"
      :fixed="fixed"
      :right="right"
      :bottom="bottom"
      fab
    >
      <v-icon dark>mdi-file-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card color="rgba(255,255,255, 0.9)">
        <v-card-title>
          <span class="headline">Poznámkový blok</span>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-textarea
              v-model="note"
              counter
              rows="18"
              :rules="rules"
              :value="value"
              :no-resize="noresize"
            ></v-textarea>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn large color="error" @click="dialog = false">Zrušit</v-btn>
          <v-btn large color="success" @click="addNote">Uložit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "../../api/api";

export default {
  props: {
    data: Object,
    fixed: {
      type: Boolean,
      default: true
    },
    right: {
      type: Boolean,
      default: true
    },
    bottom: {
      type: Boolean,
      default: true
    },
    isTutorial: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showResults: true,
      noresize: true,
      note: null,
      dialog: false,
      name: null,
      value: null,
      rules: [v => v.length <= 1000 || "Maximální počet znaků je 1000."]
    };
  },
  created() {
    api
      .post("practice/getNotes", { userId: this.data.userId })
      .then(response => {
        this.note = response.data.note;
      });
  },
  methods: {
    addNote() {
      this.data = {
        user_id: this.data.userId,
        text: this.note
      };
      api.post("/practice/addNote", this.data).then(() => {
        this.dialog = false;
      });
    },
    openDialog() {
      this.dialog = true;
    }
  }
};
</script>
<style scoped>
.v-input__slot {
  align-items: flex-start !important;
}

.tutorial-notepad {
  float: right;
  top: -41px;
  right: 388px;
}
</style>