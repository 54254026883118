<template>
  <div>
   <v-row id="score" align="center" justify="center">
      <v-simple-table>
        <tbody>
          <tr>
            <td>Vaše skóre:</td>
            <td class="points" style="color: green">{{ points }}</td>

            <td>Vaše nejvyšší skóre:</td>
            <td class="points">{{ userHighscore }}</td>

            <td>Celkové nejvyšší skóre:</td>
            <td class="points" style="color: red">{{ highScore }}</td>
          </tr>
        </tbody>
      </v-simple-table>
   </v-row>
    <v-row align="center" justify="center">
      <div id="container" ref="gameContainer">
        <v-overlay :absolute="true" :value="overlay"> </v-overlay>
        <v-card v-if="info" class="mx-auto" max-width="500">
          <v-card-text align="center">
            <h2>{{ practiceName }}</h2>
            {{ practiceInfo }}
          </v-card-text>
          <v-card-actions align="center">
            <v-row align="center" justify="center">
              <v-btn
                @click="
                  play();
                  overlay = false;
                  info = false;
                "
                x-large
                color="success"
                >Jdu na to</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-card>
        <v-card v-show="gameover" class="mx-auto" max-width="500">
          <v-card-text align="center">Zkus zachránit ještě víc pacientů!</v-card-text>
          <v-card-actions align="center">
            <v-row align="center" justify="center">
              <v-btn @click="playAgain" x-large color="amber">Hrát znovu</v-btn>
            </v-row>
            <v-row
              v-if="countRound > 2 && userHighscore > 0"
              align="center"
              justify="center"
            >
              <v-btn @click="goToPractice" x-large color="success"
                >Pokračovat</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-card>
        <div
          id="snake"
          v-bind:style="{
            top: verticalPosition + 'px',
            left: horizontalPosition + 'px',
            backgroundImage: 'url(' + ambulanceUrl + ')',
            backgroundSize: ambulanceBackgroundSize,
          }"
          ref="snake"
        ></div>
        <div id="wall-vertical" ref="wallVerical"></div>
        <div id="wall-horizontal" ref="wallHorizontal"></div>
        <div
          v-show="showPatient"
          id="patient"
          v-bind:style="{
            top: patientTop + 'px',
            left: patientLeft + 'px',
            backgroundImage: 'url(' + patientUrl + ')',
          }"
          ref="patient"
        ></div>

        <div
          id="hospital"
          v-bind:style="{
            top: '300px',
            left: '500px',
            backgroundImage: 'url(' + hospitalUrl + ')',
          }"
          ref="hospital"
        ></div>
        <div
          id="car-1"
          v-bind:style="{
            left: carPosition + 'px',
            backgroundImage: 'url(' + carUrl + ')',
          }"
          ref="car1"
        ></div>
      </div>
    </v-row>
  </div>
</template>

<script>
import { registerRoute } from "workbox-routing";
import { StaleWhileRevalidate } from "workbox-strategies";
import EventBus from "../../../event-bus";

import imgPatient from "../../../../public/patient-1.png";
import imgPatient2 from "../../../../public/patient-2.png";
import ambulanceRight from "../../../../public/ambulance-vpravo.png";
import ambulanceRightFull from "../../../../public/ambulance-vpravo-pac.png";
import ambulanceLeft from "../../../../public/ambulance-vlevo.png";
import ambulanceLeftFull from "../../../../public/ambulance-vlevo-pac.png";
import ambulanceDown from "../../../../public/ambulance-dolu-vpravo.png";
import ambulanceDownFull from "../../../../public/ambulance-dolu-vpravo-pac.png";
import ambulanceUp from "../../../../public/ambulance-nahoru-vlevo.png";
import ambulanceUpFull from "../../../../public/ambulance-nahoru-vlevo-pac.png";
import hospital1 from "../../../../public/nemocnice-plna.png";
import hospital2 from "../../../../public/nemocnice-prazdna.png";
import boom from "../../../../public/vybuch-min.png";
import car1left from "../../../../public/auto-prava.png";
import car1right from "../../../../public/auto-leva.png";
import api from "../../../api/api";

registerRoute(
  ({ url }) => url.pathname.startsWith("../../../../public/"),
  new StaleWhileRevalidate()
);

export default {
  props: {
    userId: String,
    practiceId: Number,
    externalUrl: {},
  },
  data() {
    return {
      direction: "bottom",
      speed: 15,
      repeat: 0,
      horizontalPosition: 50,
      verticalPosition: 50,
      patientTop: 300,
      patientLeft: 300,
      clientHeight: null,
      clientWidth: null,
      containerTop: null,
      containerBottom: null,
      containerLeft: null,
      containerRight: null,
      step: 3,
      showPatient: true,
      showPatient2: false,
      round: 0,
      countRound: 1,
      loaded: false,
      points: 0,
      boomState: 0,
      hospitalUrl: hospital1,
      ambulanceUrl: ambulanceRight,
      patientUrl: imgPatient,
      ambulanceState: 0,
      continue: false,
      info: true,
      ambulanceBackgroundSize: "cover",
      patientPositions: [
        [600, 500],
        [100, 500],
        [300, 500],
        [400, 700],
        [200, 300],
        [440, 200],
        [650, 800],
        [50, 50],
        [150, 320],
      ],
      imgPatient: null,
      overlay: true,
      imgPatient2: null,
      ambulanceRight: null,
      ambulanceRight2: null,
      ambulanceRightFull: null,
      ambulanceRightFull2: null,
      ambulanceLeft: null,
      ambulanceLeft2: null,
      ambulanceLeftFull: null,
      ambulanceLeftFull2: null,
      ambulanceDown: null,
      ambulanceDown2: null,
      ambulanceDownFull: null,
      ambulanceDownFull2: null,
      ambulanceUp: null,
      ambulanceUp2: null,
      ambulanceUpFull: null,
      ambulanceUpFull2: null,
      hospital1: null,
      hospital2: null,
      boom: null,
      carPosition: 0,
      carUrl: car1left,
      car1left: null,
      car1right: null,
      carDirection: "right",
      highScore: null,
      userHighscore: null,
      gameover: false,
      practiceName: null,
      practiceInfo: null,
    };
  },
  mounted() {
    this.toggleDrawer(false);

    api.post("/practice/getHighscore").then((response) => {
      if (response.data.score != null) {
        this.highScore = response.data.score;
      } else {
        this.highScore = 0;
      }
    });

    api
      .post("/practice/getUserHighscore", { user_id: this.userId })
      .then((response) => {
        if (response.data.score != null) {
          this.userHighscore = response.data.score;
        } else {
          this.userHighscore = 0;
        }
      });
    api
      .post("/practice/getPracticeInfo", { practiceId: this.practiceId })
      .then((response) => {
        this.practiceName = response.data.name;
        this.practiceInfo = response.data.describe;
      });
    var self = this;
    this.setImages();
    this.startMove();

    window.addEventListener("keyup", function (event) {
      if (event.keyCode === 39) {
        self.changeDirection("right");
      } else if (event.keyCode === 37) {
        self.changeDirection("left");
      } else if (event.keyCode === 38) {
        self.changeDirection("up");
      } else if (event.keyCode === 40) {
        self.changeDirection("down");
      }
    });
  },
  watch: {
    points() {
      if (this.points > this.userHighscore) {
        this.userHighscore = this.points;
        api.post("/practice/saveHighscore", {
          user_id: this.userId,
          score: this.points,
        });
      }
      if (this.points > this.highScore) {
        this.highScore = this.points;
      }
    },
  },
  methods: {
    goToPractice() {
      api.post("/practice/saveResult", {
        user_id: this.userId,
        course_item_id: this.practiceId,
        result: this.userHighscore,
      });
      EventBus.$emit("SWITCH_PRACTICE", "feedback", 33);
    },
    play() {
      this.info = false;

      this.clientHeight = this.$refs.gameContainer.clientHeight;
      this.clientWidth = this.$refs.gameContainer.clientWidth;

      this.containerLeft =
        this.$refs.gameContainer.getBoundingClientRect().left;
      this.containerTop = this.$refs.gameContainer.getBoundingClientRect().top;
      this.containerBottom = this.$refs.gameContainer.getBoundingClientRect().bottom;
      this.containerRight =
        this.$refs.gameContainer.getBoundingClientRect().right;

      this.continue = true;
    },
    setImages() {
      this.imgPatient = imgPatient;
      this.imgPatient2 = imgPatient2;
      this.ambulanceRight = ambulanceRight;
      this.ambulanceRightFull = ambulanceRightFull;
      this.ambulanceLeft = ambulanceLeft;
      this.ambulanceLeftFull = ambulanceLeftFull;
      this.ambulanceDown = ambulanceDown;
      this.ambulanceDownFull = ambulanceDownFull;
      this.ambulanceUp = ambulanceUp;
      this.ambulanceUpFull = ambulanceUpFull;
      this.hospital1 = hospital1;
      this.hospital2 = hospital2;
      this.boom = boom;
      this.car1left = car1left;
      this.car1right = car1right;
    },
    getPositions(box) {
      let pos = box.getBoundingClientRect();
      let width = box.getBoundingClientRect().width;
      var height = box.getBoundingClientRect().height;
      return [
        [pos.left, pos.left + width],
        [pos.top, pos.top + height],
      ];
    },
    comparePositions(p1, p2) {
      var x1 = p1[0] < p2[0] ? p1 : p2;
      var x2 = p1[0] < p2[0] ? p2 : p1;
      return x1[1] > x2[0] || x1[0] === x2[0] ? true : false;
    },
    changeDirection(direction) {
      this.direction = direction;
    },
    checkCollisionPatient() {
      let snake = this.getPositions(this.$refs.snake);
      let patient = this.getPositions(this.$refs.patient);
      let horizontalMatch = this.comparePositions(snake[0], patient[0]);
      let verticalMatch = this.comparePositions(snake[1], patient[1]);
      let match = horizontalMatch && verticalMatch;
      return match;
    },
    checkCollisionHospital() {
      let snake = this.getPositions(this.$refs.snake);
      let hospital = this.getPositions(this.$refs.hospital);
      let horizontalMatch = this.comparePositions(snake[0], hospital[0]);
      let verticalMatch = this.comparePositions(snake[1], hospital[1]);
      let match = horizontalMatch && verticalMatch;
      return match;
    },
    checkCollisionCar() {
      let snake = this.getPositions(this.$refs.snake);
      let car = this.getPositions(this.$refs.car1);
      let horizontalMatch = this.comparePositions(snake[0], car[0]);
      let verticalMatch = this.comparePositions(snake[1], car[1]);
      let match = horizontalMatch && verticalMatch;
      if (match) {
        this.continue = false;
      }
      return match;
    },
    checkCollisionWall() {
      let snake = this.getPositions(this.$refs.snake);
      let wallVerical = this.getPositions(this.$refs.wallVerical);
      let horizontalMatch = this.comparePositions(snake[0], wallVerical[0]);
      let verticalMatch = this.comparePositions(snake[1], wallVerical[1]);
      let match = horizontalMatch && verticalMatch;
      if (!match) {
        let wallHorizontal = this.getPositions(this.$refs.wallHorizontal);
        let horizontalMatch = this.comparePositions(
          snake[0],
          wallHorizontal[0]
        );
        let verticalMatch = this.comparePositions(snake[1], wallHorizontal[1]);
        match = horizontalMatch && verticalMatch;
      }
      if (match) {
        this.continue = false;
      }
      return match;
    },
    playAgain() {
      this.countRound = this.countRound + 1;
      this.loaded = false;
      console.log(this.loaded)
      this.points = 0;
      this.direction = "right";
      this.ambulanceBackgroundSize = "cover";
      this.horizontalPosition = 50;
      this.verticalPosition = 50;
      this.gameover = false;
      this.continue = true;
      this.carDirection = "right";
      this.ambulanceUrl = ambulanceRight;
      this.ambulanceState = 0;
      this.carPosition = 0;
      this.carUrl = car1right;
      this.showPatient = true;
      
    },
    changeAmbulanceState(full) {
      let right;

      if (full) {
        switch (this.direction) {
          case "right":
            right = this.ambulanceRightFull;
            break;
          case "left":
            right = this.ambulanceLeftFull;
            break;
          case "down":
            right = this.ambulanceDownFull;
            break;
          case "up":
            right = this.ambulanceUpFull;
            break;
          default:
            right = this.ambulanceRightFull;
        }
      } else {
        switch (this.direction) {
          case "right":
            right = this.ambulanceRight;
            break;
          case "left":
            right = this.ambulanceLeft;
            break;
          case "down":
            right = this.ambulanceDown;
            break;
          case "up":
            right = this.ambulanceUp;
            break;
          default:
            right = this.ambulanceRight;
        }
      }
      this.ambulanceUrl = right;
    },
    carMove() {
      let left =
        this.$refs.car1.getBoundingClientRect().left;
      let right =
        this.$refs.car1.getBoundingClientRect().right;
      let leftCheck = this.containerLeft - left;
      let rightCheck = this.containerRight - right;

      if (this.carDirection == "right") {
        this.carPosition = this.carPosition + this.step + 1;
        if (rightCheck <= 0) {
          this.carDirection = "left";
          this.carUrl = this.car1right;
        }
      } else {
        this.carPosition = this.carPosition - (this.step + 1);
        if (leftCheck >= 0) {
          this.carDirection = "right";
          this.carUrl = this.car1left;
        }
      }
    },
    move() {
      if (this.continue) {
        let left =
          this.$refs.snake.getBoundingClientRect().left;
        let top = this.$refs.snake.getBoundingClientRect().top;
        let bottom = this.$refs.snake.getBoundingClientRect().bottom;
        let right =
          this.$refs.snake.getBoundingClientRect().right;

        let leftCheck = this.containerLeft - left;
        let rightCheck = this.containerRight - right;
        let topCheck = this.containerTop - top;
        let bottomCheck = this.containerBottom - bottom;
        if (!this.checkCollisionCar()) {
          this.carMove();
        } else {
          this.carUrl = this.boom;
        }

        if (
          leftCheck < 0 &&
          rightCheck > 0 &&
          topCheck < 0 &&
          bottomCheck > 0 &&
          !this.checkCollisionWall() &&
          !this.checkCollisionCar()
        ) {
          this.repeat++;
          switch (this.direction) {
            case "right":
              this.horizontalPosition = this.horizontalPosition + this.step;
              break;
            case "left":
              this.horizontalPosition = this.horizontalPosition - this.step;
              break;
            case "down":
              this.verticalPosition = this.verticalPosition + this.step;
              break;
            case "up":
              this.verticalPosition = this.verticalPosition - this.step;
              break;
          }

          if (this.loaded) {
            if (this.checkCollisionHospital(true)) {
              this.loaded = false;
              this.hospitalUrl = hospital1;
              this.patientTop = this.patientPositions[this.round][0];
              this.patientLeft = this.patientPositions[this.round][1];
              this.round++;
              if (this.round == this.patientPositions.length) {
                this.round = 0;
              }
              this.points++;
              this.randomPatient();
              this.showPatient = true;
            }
          } else {
            if (this.checkCollisionPatient()) {
              this.loaded = true;
              this.hospitalUrl = hospital2;
              this.showPatient = false;
            }
          }
          this.changeAmbulanceState(this.loaded);
        } else {
          this.crash();
        }
      }
    },
    toggleDrawer(toggle) {
      EventBus.$emit("TOGGLE_DRAWER", toggle);
    },
    crash() {
      this.ambulanceUrl = boom;
      this.continue = false;

      while (this.boomState < 100) {
        this.boomState = this.boomState + 20;

        this.ambulanceBackgroundSize = this.boomState + "%";
      }
      this.gameover = true;
    },
    randomPatient() {
      let number = Math.floor(Math.random() * 2);
      if (number == 0) {
        this.patientUrl = imgPatient;
      } else {
        this.patientUrl = imgPatient2;
      }
    },
    startMove() {
      this.timerInterval = setInterval(() => this.move(), this.speed);
    },
  },
};
</script>

<style scoped>
#snake {
  width: 80px;
  height: 80px;
  background-position: center;
  background-size: cover;

  position: absolute;
}

#car-1 {
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: contain;
  top: 220px;
  position: absolute;
}

#patient {
  width: 60px;
  height: 60px;
  position: absolute;
  background-position: center;
  background-size: cover;
  background-image: url("../../../../public/patient-1.png");
}

#patient-2 {
  width: 60px;
  height: 60px;
  position: absolute;
  background-position: center;
  background-size: cover;
  background-image: url("../../../../public/patient-2.png");
}

#hospital {
  width: 120px;
  height: 120px;
  position: absolute;
  background-position: center;
  background-size: cover;
}

#container {
  float: left;
  height: 700px;
  width: 1000px;
  background-color: silver;
  position: relative;
}

#score {

  width: 100%;
}

#score {
  float: right;
  color: blue;
  font-size: 60px;
}

#wall-vertical {
  width: 60px;
  height: 60px;
  left: 200px;
  top: 150px;
  position: absolute;
  background-position: center;
  background-size: cover;
  background-image: url("../../../../public/strom.png");
}

#wall-horizontal {
  width: 60px;
  height: 60px;
  left: 300px;
  top: 600px;
  position: absolute;
  background-position: center;
  background-size: cover;
  background-image: url("../../../../public/strom.png");
}

.points {
  font-size: 30px;
}

.points:first-of-type {
  color: greenyellow;
}
.v-card {
  z-index: 10000;
}
</style>
