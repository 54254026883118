<template>
  <v-row align="center" justify="center">
    <v-card v-if="dialog" class="elevation-16" width="900">
      <v-card-title class="headline text-center"
        >Jak se Vám online kurz líbil?</v-card-title
      >
      <v-card-text>
        <div class="text-center mt-12">
          <v-rating
            background-color="grey lighten-1"
            color="red darken-4"
            empty-icon="mdi-heart-outline"
            full-icon="mdi-heart"
            half-icon="mdi-heart-half-full"
            hover
            v-model="rating"
            length="10"
            size="47"
          ></v-rating>
          <v-textarea
            autocomplete="email"
            rows="3"
            v-model="text"
            label="Zde můžete přidat slovní hodnocení a konkrétní podněty ke zlepšení. Děkujeme."
          ></v-textarea>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions align="center">
        <v-row v-if="rating > 0" align="center" justify="center">
          <v-btn
            @click="
              rate();
              dialog = false;
            "
            x-large
            color="success"
            >Odeslat hodnocení</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card v-if="!dialog" class="mx-auto" max-width="500">
      <v-card-text align="center">
        <h2>
          <v-icon
            class="result-icon"
            :color="(this.rating * 10) | scoreColor"
            >{{ (this.rating * 10) | scoreIcon }}</v-icon
          >
        </h2>
        <span style="font-size: 18px">
        {{ thanks }}</span><br />
        <span style="font-size: 18px">Tým Life Support</span>
      </v-card-text>
      <v-card-actions align="center">
        <v-row align="center" justify="center">
          <v-btn @click="goNext" x-large color="success"
            >Pokračovat na závěrečný test</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>
import api from "../../../api/api";
import EventBus from "../../../event-bus";

export default {
  props: {
    userId: String,
    practiceId: Number,
  },

  data() {
    return {
      rating: 0,
      text: null,
      dialog: true,
      icon: null,
      thanks: null,
    };
  },
  methods: {
    goNext() {
      EventBus.$emit("SWITCH_PRACTICE", "final", 30);
    },

    rate() {
      console.log(this.rating);
      if (this.rating < 5) {
        this.thanks =
          "Mrzí nás, že Vás náš kurz nezaujal. Pokud nám posíláte nápady na změnu, pokusíme se je zapracovat.";
        this.icon = "";
      } else if (this.rating > 4 && this.rating < 8) {
        this.thanks =
          "Děkujeme za Váš názor, pokud jste nám poslali náměty jaké věci zlepšit, pokusíme se kurz změnit k lepšímu.";
        this.icon = "";
      } else if (this.rating > 7 && this.rating < 10) {
        this.thanks =
          "Moc děkujeme za Váš názor, těší nás, že se Vám kurz líbil. Případné podněty na zlepšení se pokusíme zapracovat.";
        this.icon = "";
      } else if (this.rating == 10) {
        this.thanks =
          "Vaše zpětná vazba nás opravdu moc těší! Děkujeme a těšíme se na další spolupráci!";
        this.icon = "";
      }
      api.post("/practice/saveResult", {
        user_id: this.userId,
        course_item_id: this.practiceId,
        result: 100,
      });
      api.post("/practice/feedback", {
        user_id: this.userId,
        value: this.rating,
        text: this.text,
      });
    },
  },
};
</script>

<style scoped>
.headline {
  text-align: center;
  padding: 20px;
}
</style>

