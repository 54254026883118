var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-overlay',{attrs:{"absolute":true,"value":_vm.overlay}}),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.dialog),expression:"dialog"}],staticClass:"mx-auto",attrs:{"max-width":"500"}},[_c('v-card-text',{attrs:{"align":"center"}},[_c('h2',[_vm._v(_vm._s(_vm.practiceName))]),_vm._v(" "+_vm._s(_vm.practiceInfo)+" ")]),_c('v-card-actions',{attrs:{"align":"center"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-btn',{attrs:{"x-large":"","color":"success"},on:{"click":function($event){_vm.dialog = false;
            _vm.overlay = false;
            _vm.getInitialsPositions();}}},[_vm._v("Jdu na to")])],1)],1)],1),(_vm.score === null)?_c('div',[_c('v-row',{staticStyle:{"margin-top":"-3vw"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"id":"left-drag"}},[_c('div',{directives:[{name:"draggable",rawName:"v-draggable",value:(_vm.draggableValue),expression:"draggableValue"}]}),_c('div',{directives:[{name:"draggable",rawName:"v-draggable",value:(_vm.draggableValue),expression:"draggableValue"}],staticClass:"aed-drag",style:({
            backgroundImage: 'url(' + _vm.aed7 + ')',
          }),attrs:{"id":"aed-7"},on:{"click":function($event){return _vm.aed7sound.play()},"ondrag":function($event){return _vm.test()}}},[_c('div',{ref:_vm.handleId})]),_c('div',{directives:[{name:"draggable",rawName:"v-draggable",value:(_vm.draggableValue),expression:"draggableValue"}],staticClass:"aed-drag",style:({
            backgroundImage: 'url(' + _vm.aed2 + ')',
          }),attrs:{"id":"aed-2"},on:{"click":function($event){return _vm.aed2sound.play()},"ondrag":function($event){return _vm.test()}}},[_c('div',{ref:_vm.handleId})]),_c('div',{directives:[{name:"draggable",rawName:"v-draggable",value:(_vm.draggableValue),expression:"draggableValue"}],staticClass:"aed-drag",style:({
            backgroundImage: 'url(' + _vm.aed3 + ')',
          }),attrs:{"id":"aed-3"},on:{"click":function($event){return _vm.aed3sound.play()},"ondrag":function($event){return _vm.test()}}},[_c('div',{ref:_vm.handleId})]),_c('div',{directives:[{name:"draggable",rawName:"v-draggable",value:(_vm.draggableValue),expression:"draggableValue"}],ref:"aaa",staticClass:"aed-drag",style:({
            backgroundImage: 'url(' + _vm.aed8 + ')',
          }),attrs:{"id":"aed-8"},on:{"click":function($event){return _vm.aed8sound.play()},"ondrag":function($event){return _vm.test()}}},[_c('div',{ref:_vm.handleId})])]),_c('v-col',{staticStyle:{"height":"38vw"}},[_c('img',{attrs:{"id":"round","src":_vm.background}}),_vm._l((8),function(index){return _c('div',{key:index,ref:'drop' + index,refInFor:true,staticClass:"slot",attrs:{"id":'drop' + index}},[_c('div',[_vm._v(_vm._s(index))])])})],2),_c('v-col',{attrs:{"id":"right-drag"}},[_c('div',{directives:[{name:"draggable",rawName:"v-draggable",value:(_vm.draggableValue),expression:"draggableValue"}],staticClass:"aed-drag",style:({
            backgroundImage: 'url(' + _vm.aed4 + ')',
          }),attrs:{"id":"aed-4"},on:{"click":function($event){return _vm.aed4sound.play()},"ondrag":function($event){return _vm.test()}}},[_c('div',{ref:_vm.handleId})]),_c('div',{directives:[{name:"draggable",rawName:"v-draggable",value:(_vm.draggableValue),expression:"draggableValue"}],staticClass:"aed-drag",style:({
            backgroundImage: 'url(' + _vm.aed5 + ')',
          }),attrs:{"id":"aed-5"},on:{"click":function($event){return _vm.aed5sound.play()},"ondrag":function($event){return _vm.test()}}},[_c('div',{ref:_vm.handleId})]),_c('div',{directives:[{name:"draggable",rawName:"v-draggable",value:(_vm.draggableValue),expression:"draggableValue"}],staticClass:"aed-drag",style:({
            backgroundImage: 'url(' + _vm.aed6 + ')',
          }),attrs:{"id":"aed-6"},on:{"click":function($event){return _vm.aed6sound.play()},"ondrag":function($event){return _vm.test()}}},[_c('div',{ref:_vm.handleId})]),_c('div',{directives:[{name:"draggable",rawName:"v-draggable",value:(_vm.draggableValue),expression:"draggableValue"}],ref:_vm.ahoj,staticClass:"aed-drag",style:({
            backgroundImage: 'url(' + _vm.aed1 + ')',
          }),attrs:{"id":"aed-1"},on:{"click":function($event){return _vm.aed1sound.play()},"ondrag":function($event){return _vm.test()}}},[_c('div',{ref:_vm.handleId})])])],1),_c('v-row',{staticStyle:{"height":"3vw"},attrs:{"align":"center","justify":"center"}},[(_vm.button)?_c('v-btn',{attrs:{"x-large":"","color":"success"},on:{"click":_vm.countResult}},[_vm._v("Vyhodnotit")]):_vm._e()],1)],1):_c('div',[_c('Results',{attrs:{"userId":this.userId,"itemId":this.practiceId,"score":this.score,"settings":this.resultSettings}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }