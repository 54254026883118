<template>
  <v-container fluid fill-height class="loginOverlay">
    <v-row  align="center" justify="center">
      <v-alert style="font-size: 14px" text prominent type="error" icon="mdi-cloud-alert">
        Elearning je optimalizován pro prohlížeče
        <a href="https://www.google.com/intl/cs_CZ/chrome/">Google Chrome</a>,
        <a href="https://www.mozilla.org/cs/firefox/new/">Mozilla Firefox</a> a
        <a href="https://www.microsoft.com/cs-cz/edge">Microsoft Edge</a>.
        Použijte prosím jeden z těchto volně dostupných prohlížečů.
      </v-alert>
    </v-row>
    <v-row v-if="mobile" align="center" justify="center">
      <v-alert text prominent type="error" icon="mdi-cloud-alert">
        Elearning momentálně není optimalizovaný pro mobilní zařízení. Použijte
        prosím počítačový prohlížeč.
      </v-alert>
    </v-row>
    <v-layout flex align-center justify-center>
      <v-flex xs12 sm4 elevation-6>
        <v-toolbar>
          <v-toolbar-title style="color: #c62828">
            <h3>Přihlášení</h3>
          </v-toolbar-title>
        </v-toolbar>
        <v-card>
          <v-card-text class="pt-4">
            <div>
              <v-form v-show="!loading" v-model="valid" ref="form">
                <v-alert v-show="error" type="error"
                  >Zadali jste neplatné přihlašovací údaje.</v-alert
                >
                <v-alert v-show="info" type="info"
                  >Pokud se Vám nedaří přihlásit, pravděpodobně náš robot
                  neeviduje platbu za online kurz. V případě, že problém
                  přetrvává déle než hodinu po zaplacení, neváhejte nás
                  kontaktovat na:
                  <a
                    style="color: white"
                    href="mailto:ondrej.brezina@lifesupport.cz"
                    >ondrej.brezina@lifesupport.cz</a
                  >
                </v-alert>
                <v-text-field
                  placeholder="Vaše e-mailová adresa"
                  v-model="username"
                  :rules="emailRules"
                  required
                ></v-text-field>
                <v-text-field
                  placeholder="Vaše heslo"
                  v-model="password"
                  min="8"
                  :append-icon="e1 ? 'mdi-eye' : 'mdi-eye-off '"
                  :append-icon-cb="() => (e1 = !e1)"
                  :type="e1 ? 'password' : 'text'"
                  :rules="passwordRules"
                  counter
                  required
                  autocomplete="on"
                  @click:append="e1 = !e1"
                ></v-text-field>

                <v-row align="center" justify="center">
                  <v-btn
                    style="margin: 1vw"
                    x-large
                    @click="submit()"
                    :class="{
                      'red darken-3 white--text': valid,
                      disabled: !valid,
                    }"
                    >Přihlásit</v-btn
                  >
                </v-row>
                <v-row align="center" justify="center">
                  <a href="https://www.lifesupport.cz/klient?zapomenute-heslo=1"
                    >Zapomenuté heslo</a
                  >
                </v-row>
              </v-form>
              <v-row
                class="loading"
                v-show="loading"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="red"
                ></v-progress-circular>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

 <script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      valid: false,
      info: false,
      error: false,
      loading: false,
      unsupportedBrowser: false,
      mobile: false,
      e1: true,
      password: "",
      passwordRules: [(v) => !!v || "Heslo je povinné"],
      username: "",
      emailRules: [(v) => !!v || "E-mail je povinný"],
    };
  },
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    this.username = urlParams.get("email");

    this.mobile = this.isMobile();
    if (this.$browserDetect.isIE || this.$browserDetect.isOpera) {
      this.unsupportedBrowser = true;
    }
  },
  methods: {
    ...mapActions({
      signIn: "auth/signIn",
    }),
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    credentials() {
      return { username: this.username, password: this.password };
    },
    clear() {
      this.$refs.form.reset();
    },
    submit() {
      this.loading = true;
      this.info = false;
      this.error = false;
      this.signIn(this.credentials())
        .then(() => {
          this.loading = false;
          this.$router.push({
            path: "/moje-kurzy",
          });
        })
        .catch((error) => {
          if (error == "403") {
            this.error = true;
          }
          if (error == "noElearning") {
            this.info = true;
          }
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.v-alert--prominent .v-alert__icon {
  min-width: 30px !important;
  height: 30px !important;
}
.loading {
  height: 144px;
}
</style>