<template>
  <div v-if="score !== null">
    <Results
      :userId="this.userId"
      :itemId="this.practiceId"
      :score="this.score"
      :settings="this.resultSettings"
      :practice="this.practiceName"
    />
  </div>
  <div class="justify-content" v-else>
    <Bookmark :data="bookmarkData" />
    <v-overlay :absolute="true" :value="overlay"> </v-overlay>
    <v-card v-show="dialog" class="mx-auto" max-width="500">
      <v-card-text align="center">
        <h2>{{ practiceNameApi }}</h2>
        {{ practiceInfo }}
      </v-card-text>
      <v-card-actions align="center">
        <v-row align="center" justify="center">
          <v-btn
            @click="
              dialog = false;
              overlay = false;
            "
            x-large
            color="success"
            >Jdu na to</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
    <v-row>
      <v-col cols="10"
        ><div class="mobile-timer">
          Zbývající čas: <b>{{ formattedTimeLeft }}</b>
        </div></v-col
      >
      <v-col cols="2">
        <div class="base-timer">
          <svg
            class="base-timer__svg"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g class="base-timer__circle">
              <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45" />
              <path
                :stroke-dasharray="circleDasharray"
                class="base-timer__path-remaining"
                :class="remainingPathColor"
                d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
              />
            </g>
          </svg>
          <span class="base-timer__label">{{ formattedTimeLeft }}</span>
        </div>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" v-if="loading">
      <v-progress-circular indeterminate color="red"></v-progress-circular>
    </v-row>
    <v-row v-else align="center" justify="center">
      <v-col cols="12">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <template v-for="n in steps">
              <v-stepper-step
                color="red-darken-3"
                :key="`${n}-step`"
                :step="n"
                :complete="e1 > n"
              ></v-stepper-step>
              <v-divider v-if="n !== steps" :key="n"></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content
              color="red"
              v-for="n in steps"
              :key="`${n}-content`"
              :step="n"
            >
              <h1 align="center" class="display-1 question-content">
                {{ questions[n - 1].content }}
              </h1>

              <v-row align="center" justify="center">
                <v-progress-circular
                  v-show="questionLoading"
                  indeterminate
                  color="red"
                ></v-progress-circular>
                <v-btn-toggle v-show="!questionLoading" borderless shaped>
                  <v-btn
                    @click="next(n, true, questions[n - 1].question_id)"
                    x-large
                    value="right"
                    color="success"
                    dark
                  >
                    <v-icon left>mdi-check-bold</v-icon>
                    <span class="hidden-sm-and-down">PRAVDA</span>
                  </v-btn>

                  <v-btn
                    @click="next(n, false, questions[n - 1].question_id)"
                    x-large
                    value="right"
                    color="red"
                  >
                    <v-icon left>mdi-close-thick</v-icon>
                    <span class="hidden-sm-and-down">Nepravda</span>
                  </v-btn>
                </v-btn-toggle>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import api from "../../../api/api";
import EventBus from "../../../event-bus";
import Results from "../../Tools/Results";
import Bookmark from "../../Tools/Bookmark";

const FULL_DASH_ARRAY = 283;
const WARNING_THRESHOLD = 10;
const ALERT_THRESHOLD = 5;

const COLOR_CODES = {
  info: {
    color: "green",
  },
  warning: {
    color: "orange",
    threshold: WARNING_THRESHOLD,
  },
  alert: {
    color: "red",
    threshold: ALERT_THRESHOLD,
  },
};

export default {
  components: {
    Results,
    Bookmark,
  },
  props: {
    userId: String,
    practiceId: Number,
  },
  data: () => ({
    questionLoading: false,
    practiceName: "truefalse",
    e1: 1,
    resultSettings: {
      goToVideo: false,
      goToPractice: null,
    },
    steps: 1,
    loading: true,
    overlay: true,
    step: null,
    questions: {},
    timePassed: 0,
    results: [],
    drawer: null,
    score: null,
    timeLimit: 90,
    timerInterval: null,
    timer: 90,
    compress: null,
    lastCompress: null,
    count: null,
    testStarted: false,
    interval: false,
    dialog: true,
    practiceNameApi: null,
    practiceInfo: null,
  }),
  mounted() {
    api
      .post("/practice/getPracticeInfo", { practiceId: this.practiceId })
      .then((response) => {
        this.loading = false;
        this.practiceNameApi = response.data.name;
        this.practiceInfo = response.data.describe;
      });
  },
  created() {
    api
      .post("practice/trueFalse/getQuestions", {
        course: 1,
        practiceId: this.practiceId,
      })
      .then((response) => {
        this.questions = response.data;
        this.initialize();
      });
  },
  computed: {
    bookmarkData() {
      return {
        userId: this.userId,
        practiceId: this.practiceId,
        videoTime: null,
      };
    },
    skillAnswers() {
      return this.answers.map((answer) => answer);
    },
    circleDasharray() {
      return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
    },

    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },

    timeLeft() {
      return this.timeLimit - this.timePassed;
    },

    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.timeLimit;
      return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction);
    },

    remainingPathColor() {
      const { alert, warning, info } = COLOR_CODES;

      if (this.timeLeft <= alert.threshold) {
        return alert.color;
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      }
    },
  },
  watch: {
    timeLeft(newValue) {
      this.timer = newValue;
      if (newValue === 0) {
        this.onTimesUp();
      }
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
    e1() {
      if (!this.testStarted) {
        this.testStarted = true;
        this.startTimer();
      }
      this.toggleDrawer(false);
    },
  },
  methods: {
    goToVideo() {
      if (this.practiceId == 5) {
        this.$parent.openPractice(
          "video",
          12,
          "07_Volani.mp4",
          '{"relatedVideos": false, "nextPractice": "table"}',
          true
        );
      } else if (this.practiceId == 26) {
        this.$parent.openPractice(
          "video",
          20,
          "15_AED.mp4",
          '{"relatedVideos": false, "goToPractice": "aed", "practiceId": "27"}',
          true
        );
      } else if (this.practiceId == 24) {
        this.$parent.openPractice(
          "video",
          16,
          "11_Stav_vedomi.mp4",
          '{"relatedVideos": false, "goToPractice": "crossword", "practiceId": "6"}',
          true
        );
      }
    },
    reset(practiceId) {
      this.$parent.switchPractice("truefalse", practiceId);
      api
        .post("practice/trueFalse/getQuestions", {
          course: 1,
          practiceId: this.practiceId,
        })
        .then((response) => {
          this.questions = response.data;
          this.initialize();
        });
    },
    initialize() {
      if (this.practiceId == 5) {
        this.resultSettings.goToVideo = true;
      } else if (this.practiceId == 26) {
        this.resultSettings.goToVideo = true;
      } else if (this.practiceId == 24) {
        this.resultSettings.goToVideo = true;
      }
      this.steps = this.questions.length;
    },
    toggleDrawer(toggle) {
      EventBus.$emit("TOGGLE_DRAWER", toggle);
    },
    onTimesUp() {
      clearInterval(this.timerInterval);
      if (this.score === null) {
        this.getScore();
      }
    },
    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    getScore() {
      api
        .post("practice/getScore", {
          user_id: this.userId,
          practiceId: this.practiceId,
        })
        .then((response) => {
          if (this.practiceId == 26) {
            this.resultSettings.goToVideo = true;
          }
          this.score = response.data.score;
        });
    },
    previous(n) {
      this.e1 = n - 1;
    },
    next(n, answer, question) {
      this.questionLoading = true;
      api
        .post("practice/answer", {
          user_id: this.userId,
          question_id: question,
          answer: answer,
        })
        .then(() => {
          this.questionLoading = false;
          if (n === this.steps) {
            this.getScore();
          } else {
            this.e1 = n + 1;
          }
        });
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 1300px) {
  .col-10 {
    width: 100%;
  }
  .col-2 {
    display: none;
  }
}
</style>
<style>
.buttons-area button {
  margin-right: 20px;
}
.question-content {
  padding: 50px;
  height: auto;
}
.v-stepper__step--active > .v-stepper__step__step {
  background-color: red !important;
}
.result-icon {
  font-size: 180px !important;
  margin-bottom: 20px;
}
.mobile-timer {
  display: none;
}
@media only screen and (max-width: 1300px) {
  .base-timer {
    display: none;
  }
  .mobile-timer {
    display: block;
    text-align: center;
  }
}
</style>
<style scoped lang="scss">
.base-timer {
  position: relative;
  width: 150px;
  height: 150px;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }

  &__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: rgb(65, 184, 131);
    }

    &.orange {
      color: orange;
    }

    &.red {
      color: red;
    }
  }

  &__label {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
  }
}
</style>