<template>
  <div>
    <v-btn
      title="Přidat záložku"
      @click="openDialog"
      v-bind:class="{ 'mx-2': !isTutorial, 'my-24': !isTutorial, 'tutorial': isTutorial }"
      dark
      color="success"
      :fixed="fixed"
      :right="right"
      :bottom="bottom"
      fab
    >
      <v-icon dark>mdi-bookmark-multiple-outline</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card color="rgba(255,255,255, 0.9)">
        <v-card-title class="headline">Uložit záložku:</v-card-title>

        <v-card-text>
          <v-text-field :rules="['Required']" v-model="name" label="Název" type="text"></v-text-field>
        </v-card-text>

        <v-card-text
          class="bookmark-info"
          v-if="!data.videoTime"
        >Odpovědi z toho cvičení nebudou uloženy, cvičení je nutné ze záložky začít od začátku!</v-card-text>

        <v-card-actions class="justify-center">
          <v-btn large color="error" @click="dialog = false">Zrušit</v-btn>

          <v-btn large color="success" @click="addBookmark">Uložit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "../../api/api";

export default {
  props: {
    data: Object,
    fixed: {
      type: Boolean,
      default: true
    },
    right: {
      type: Boolean,
      default: true
    },
    bottom: {
      type: Boolean,
      default: true
    },
    isTutorial: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showResults: true,
      dialog: false,
      name: null
    };
  },
  methods: {
    addBookmark() {
      this.data.name = this.name;
      this.data = {
        item_id: this.data.practiceId,
        name: this.data.name,
        user_id: this.data.userId,
        videoTime: this.data.videoTime
      };
      api.post("/practice/addBookmark", this.data).then(() => {
        this.dialog = false;
      });
    },
    openDialog() {
      console.log(this.data);
      api.get("practice/getItem/" + this.data.practiceId).then(response => {
        this.name = response.data.name;
        console.log(this.name);
        this.dialog = true;
      });
    }
  }
};
</script>
<style scoped>
.my-24 {
  bottom: 90px;
}

.tutorial {
  float: right;
  top: -43px;
  right: 22vw;
}
</style>

<style>
.bookmark-info {
  color: red !important;
}
</style>