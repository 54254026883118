
<template>
  <div class="container">
    <h3>Nastavení</h3>
    <v-form v-show="!loading" v-model="valid">
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="email"
              :counter="10"
              label="E-mail"
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-text-field
              v-model="title"
              :counter="10"
              label="Titul"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-text-field
              v-model="firstname"
              :counter="10"
              label="Křestní jméno"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-text-field
              v-model="lastname"
              :counter="10"
              label="Příjmení"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-text-field
              v-model="company"
              :counter="10"
              label="Firma"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-btn class="replay-button" @click="save()" x-large color="success">
            Uložit nastavení
            <v-icon dark right>mdi-content-save-settings</v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </v-form>
    <v-row class="loading" v-show="loading" align="center" justify="center">
      <v-progress-circular indeterminate color="red"></v-progress-circular>
    </v-row>
    <div class="text-center ma-2">
      <v-snackbar v-model="snackbar" color="success">
        {{ text }}
        <template>
          <v-btn dark text v-bind="attrs" @click="snackbar = false"
            >Zavřít</v-btn
          >
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import api from "../../api/ls";

export default {
  data() {
    return {
      email: null,
      firstname: null,
      loading: true,
      lastname: null,
      company: null,
      title: null,
      snackbar: false,
      text: "Nastavení bylo uloženo.",
    };
  },
  methods: {
    save() {
      this.loading = true;
      api
        .post("af_restServer/v1/af_user", {
          user_email: this.email,
          name: this.firstname,
          surname: this.lastname,
          title: this.title,
          company_name: this.company,
        })
        .then((response) => {
          this.email = response.data.user_email;
          this.firstname = response.data.name;
          this.lastname = response.data.surname;
          this.title = response.data.title;
          this.snackbar = true;
          this.loading = false;
        });
    },
  },
  created() {
    api.get("af_restServer/v1/af_user").then((response) => {
      this.email = response.data.user_email;
      this.firstname = response.data.name;
      this.lastname = response.data.surname;
      this.title = response.data.title;
      if (response.data.company_name) {
        this.company = response.data.company_name;
      } else {
        this.company = "";
      }

      this.loading = false;
    });
  },
};
</script>
<style scoped>
.loading {
  height: 500px;
}
</style>