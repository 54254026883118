<template>
  <v-container>
    <v-card>
      <v-tabs v-model="tab" background-color="red darken-3" centered dark icons-and-text>
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          Technický dotaz
          <v-icon>mdi-help-network</v-icon>
        </v-tab>

        <v-tab href="#tab-2">
          Odborný dotaz ke kurzu
          <v-icon>mdi-comment-question</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat>
            <v-card-text v-show="textarea1">
              <v-textarea clearable label="Zde napiště Váš dotaz" v-model="text1"></v-textarea>
              <br />
              <br />
              <br />
              <br />
              <h3>Odpověď bude odeslána na e-mail:</h3>
              <br />
              <v-text-field v-model="email" :rules="[rules.required, rules.email]" label="E-mail"></v-text-field>
              <v-row align="center" justify="center">
                <v-btn class="replay-button" @click="send(1)" x-large color="success">
                  Odeslat dotaz
                  <v-icon dark right>mdi-email-send-outline</v-icon>
                </v-btn>
              </v-row>
            </v-card-text>
            <v-card-text v-show="!textarea1">
              <br />
              <br />
              <br />
              <br />
              <v-alert type="success">Dotaz byl úspěšně odeslán.</v-alert>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-card flat>
            <v-card-text v-show="textarea2">
              <v-textarea clearable label="Zde napiště Váš dotaz" v-model="text2"></v-textarea>
              <br />
              <br />
              <br />
              <br />
              <h3>Odpověď bude odeslána na e-mail:</h3>
              <br />
              <v-text-field v-model="email" :rules="[rules.required, rules.email]" label="E-mail"></v-text-field>
              <v-row align="center" justify="center">
                <v-btn class="replay-button" @click="send(2)" x-large color="success">
                  Odeslat dotaz
                  <v-icon dark right>mdi-email-send-outline</v-icon>
                </v-btn>
              </v-row>
            </v-card-text>
            <v-card-text v-show="!textarea2">
              <br />
              <br />
              <br />
              <br />
              <v-alert type="success">Dotaz byl úspěšně odeslán.</v-alert>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../../api/api";

export default {
  components: {},
  data() {
    return {
      tab: null,
      text1: "",
      text2: "",
      textarea1: true,
      textarea2: true,
      rules: {
        required: value => !!value || "Required.",
        counter: value => value.length <= 20 || "Max 20 characters",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Neplatný e-mail.";
        }
      }
    };
  },
  mounted: {},
  methods: {
    send(id) {
      if (id === 1) {
        this.textarea1 = false;
        api.post("practice/sendQuestion", {
          userId: this.user.email,
          text: this.text1,
          type: 1
        });
      } else if (id === 2) {
        this.textarea2 = false;
        api.post("practice/sendQuestion", {
          userId: this.user.email,
          text: this.text2,
          type: 2
        });
      }
    }
  },
  created() {},
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user"
    }),
    email() {
      return this.user.email;
    }
  }
};
</script>
<style scoped>
</style>