  <template>
  <div v-if="!score" id="app">
    <Bookmark :data="bookmarkData" />
    <div class="container">
      <puzzle-board
        :src="src"
        :width="width"
        :height="height"
        :cols="cols"
        :rows="rows"
        :autoResize="autoresize"
        :showNumber="showNumber"
        @start="startGame"
        @finish="finishGame"
      />
      <v-row class="complete-button" align="center" justify="center">
        <v-btn v-show="skipButton" @click="finishGame" x-large color="success">
          <v-icon dark left>mdi-check</v-icon>Přeskočit
        </v-btn>
        <v-btn v-show="countDown" disabled x-large color="success">
          <v-icon dark left>mdi-check</v-icon>
          Přeskočit za {{ timeout }}
        </v-btn>
      </v-row>
    </div>
  </div>
  <div v-else>
    <Results
      :userId="this.userId"
      :itemId="this.practiceId"
      :score="this.score"
      :settings="{}"
    />
  </div>
</template>

<script>
import PuzzleBoard from "vue-8-puzzle";
import img from "../../../../public/dopravni_nehoda.jpg";
import Results from "../../Tools/Results";
import EventBus from "../../../event-bus";
import Bookmark from "../../Tools/Bookmark";

export default {
  components: {
    PuzzleBoard,
    Results,
    Bookmark,
  },
  data() {
    return {
      cols: 3,
      rows: 3,
      width: 480,
      height: 480,
      autoresize: false,
      showNumber: true,
      score: null,
      countDown: true,
      skipButton: false,
      timeout: 120,
    };
  },
  mounted() {
    this.toggleDrawer(true);
  },
  methods: {
    startGame() {
      this.toggleDrawer(false);
      this.showSkipButton();
    },
    toggleDrawer(toggle) {
      EventBus.$emit("TOGGLE_DRAWER", toggle);
    },
    finishGame() {
      this.score = 100;
    },
  },
  computed: {
    src() {
      return img;
    },

    bookmarkData() {
      return {
        userId: this.userId,
        practiceId: this.practiceId,
        videoTime: null,
      };
    },
  },
  watch: {
    timeout: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timeout--;
          }, 1000);
        } else {
          this.countDown = false;
          this.skipButton = true;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
};
</script>
<style scoped>
body {
  margin: 0;
  padding: 0;
  background-color: #fafafa;
}
.container {
  position: absolute;
  top: calc(50% + 10px);
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  width: 90%;
  height: calc(90% - 60px);
  margin: 0px;
  padding: 0px;
  z-index: 1;
  background-color: #ddd;
  max-width: 480px;
  max-height: 480px;
}
.complete-button button {
  top: 515px;
}
</style>