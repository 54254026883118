<template>
<div>
      <v-app-bar
      app
      clipped-left
      color="red darken-3"
      dense
    >
     
      <v-icon
        class="mx-4"
        large
      >
        mdi-school
      </v-icon>
      <v-toolbar-title class="mr-12 align-center">
        <span class="main-title">E-learning Life Support</span>
      </v-toolbar-title>
      <v-spacer />
   
    </v-app-bar>
    </div>
</template>

<script>

  export default {
      }

</script>

<style>
.main-title {
  color: white;
}
</style>