    <template>
  <div>
    <v-overlay :absolute="true" :value="overlay"> </v-overlay>
    <v-card v-show="dialog" class="mx-auto" max-width="500">
      <v-card-text align="center">
        <h2>{{ practiceName }}</h2>
        {{ practiceInfo }}
      </v-card-text>
      <v-card-actions align="center">
        <v-row align="center" justify="center">
          <v-btn
            @click="
              dialog = false;
              overlay = false;
              getInitialsPositions();
            "
            x-large
            color="success"
            >Jdu na to</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
    <div v-if="score === null">
      <v-row style="margin-top: -3vw" align="center" justify="center">
        <v-col id="left-drag">
          <div v-draggable="draggableValue"></div>
          <div
            id="aed-7"
            class="aed-drag"
            @click="aed7sound.play()"
            @ondrag="test()"
            v-draggable="draggableValue"
            v-bind:style="{
              backgroundImage: 'url(' + aed7 + ')',
            }"
          >
            <div :ref="handleId"></div>
          </div>

          <div
            id="aed-2"
            class="aed-drag"
            @click="aed2sound.play()"
            @ondrag="test()"
            v-draggable="draggableValue"
            v-bind:style="{
              backgroundImage: 'url(' + aed2 + ')',
            }"
          >
            <div :ref="handleId"></div>
          </div>
          <div
            id="aed-3"
            class="aed-drag"
            @click="aed3sound.play()"
            @ondrag="test()"
            v-draggable="draggableValue"
            v-bind:style="{
              backgroundImage: 'url(' + aed3 + ')',
            }"
          >
            <div :ref="handleId"></div>
          </div>
          <div
            id="aed-8"
            class="aed-drag"
            @click="aed8sound.play()"
            @ondrag="test()"
            v-draggable="draggableValue"
            ref="aaa"
            v-bind:style="{
              backgroundImage: 'url(' + aed8 + ')',
            }"
          >
            <div :ref="handleId"></div>
          </div>
        </v-col>
        <v-col style="height: 38vw">
          <img id="round" v-bind:src="background" />
          <div
            v-for="index in 8"
            :key="index"
            v-bind:id="'drop' + index"
            class="slot"
            v-bind:ref="'drop' + index"
          >
            <div>{{ index }}</div>
          </div>
        </v-col>
        <v-col id="right-drag">
          <div
            id="aed-4"
            class="aed-drag"
            @click="aed4sound.play()"
            @ondrag="test()"
            v-draggable="draggableValue"
            v-bind:style="{
              backgroundImage: 'url(' + aed4 + ')',
            }"
          >
            <div :ref="handleId"></div>
          </div>
          <div
            id="aed-5"
            class="aed-drag"
            @click="aed5sound.play()"
            @ondrag="test()"
            v-draggable="draggableValue"
            v-bind:style="{
              backgroundImage: 'url(' + aed5 + ')',
            }"
          >
            <div :ref="handleId"></div>
          </div>
          <div
            id="aed-6"
            class="aed-drag"
            @click="aed6sound.play()"
            @ondrag="test()"
            v-draggable="draggableValue"
            v-bind:style="{
              backgroundImage: 'url(' + aed6 + ')',
            }"
          >
            <div :ref="handleId"></div>
          </div>
          <div
            id="aed-1"
            v-bind:ref="ahoj"
            class="aed-drag"
            @click="aed1sound.play()"
            @ondrag="test()"
            v-draggable="draggableValue"
            v-bind:style="{
              backgroundImage: 'url(' + aed1 + ')',
            }"
          >
            <div :ref="handleId"></div>
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" style="height: 3vw">
        <v-btn v-if="button" @click="countResult" x-large color="success"
          >Vyhodnotit</v-btn
        >
      </v-row>
    </div>
    <div v-else>
      <Results
        :userId="this.userId"
        :itemId="this.practiceId"
        :score="this.score"
        :settings="this.resultSettings"
      />
    </div>
  </div>
</template>
<script>
import { Draggable } from "@cmcleese/draggable-vue-directive";
import aed1 from "../../../../public/aed/1-zkontroluj_vedomi.png";
import background from "../../../../public/aed/kruhAED.png";
import aed1sound from "../../../../public/aed/1-zkontroluj_vedomi.wav";
import aed2 from "../../../../public/aed/2-privolej_pomoc.png";
import aed2sound from "../../../../public/aed/2-privolej_pomoc.wav";
import aed3 from "../../../../public/aed/3-uvolni_DC.png";
import aed3sound from "../../../../public/aed/3-uvolni_DC.wav";
import aed4 from "../../../../public/aed/4-zkontroluj_dychani.png";
import aed4sound from "../../../../public/aed/4-zkontroluj_dychani.wav";
import aed5 from "../../../../public/aed/5-nalep_elektrody.png";
import aed5sound from "../../../../public/aed/5-nalep_elektrody.wav";
import aed6 from "../../../../public/aed/6-analyzuji.png";
import aed6sound from "../../../../public/aed/6-analyzuji.wav";
import aed7 from "../../../../public/aed/7-stiskni_tlacitko.png";
import aed7sound from "../../../../public/aed/7-stiskni_tlacitko.wav";
import aed8 from "../../../../public/aed/8-zacni_resuscitovat.png";
import zoll from "../../../../public/aed/zoll-logo.png";
import aed8sound from "../../../../public/aed/8-zacni_resuscitovat.wav";
import Results from "../../Tools/Results";
import api from "../../../api/api";
import EventBus from "../../../event-bus";
export default {
  directives: {
    Draggable,
  },
  props: {
    userId: String,
    practiceId: Number,
  },
  components: {
    Results,
  },
  data() {
    return {
      resultSettings: {
        goToVideo: true,
      },
      complete: false,
      drop1: null,
      drop2: null,
      drop3: null,
      drop4: null,
      drop5: null,
      drop6: null,
      drop7: null,
      drop8: null,
      button: false,
      res1: "",
      res2: "",
      res3: "",
      res4: "",
      res5: "",
      res6: "",
      res7: "",
      res8: "",
      result: [],
      count: 0,
      score: null,
      ahoj: "ahoj",
      change: 0,
      aed1: aed1,
      overlay: true,
      zoll: zoll,
      background: background,
      aed1sound: new Audio(aed1sound),
      aed2: aed2,
      aed2sound: new Audio(aed2sound),
      aed3: aed3,
      aed3sound: new Audio(aed3sound),
      aed4: aed4,
      aed4sound: new Audio(aed4sound),
      aed5: aed5,
      aed5sound: new Audio(aed5sound),
      aed6: aed6,
      aed6sound: new Audio(aed6sound),
      aed7: aed7,
      aed7sound: new Audio(aed7sound),
      aed8: aed8,
      aed8sound: new Audio(aed8sound),
      handleId: "handle-id",
      draggableValue: {
        onDragEnd: this.onPosChanged,
      },
      dialog: true,
      practiceName: null,
      practiceInfo: null,
    };
  },
  mounted() {
    document.getElementById("round").ondragstart = function () {
      return false;
    };
    this.draggableValue.handle = this.$refs[this.handleId];
    this.draggableValue.onDragStart = this.test;
    this.toggleDrawer(false);
    api
      .post("/practice/getPracticeInfo", { practiceId: this.practiceId })
      .then((response) => {
        this.practiceName = response.data.name;
        this.practiceInfo = response.data.describe;
      });
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    window.scrollTo(0, 0);
    document.getElementsByTagName("html")[0].style.overflow = "hidden";
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
    document.getElementsByTagName("html")[0].style.overflow = "scroll";
  },
  updated() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    window.scrollTo(0, 0);
    document.getElementsByTagName("html")[0].style.overflow = "hidden";
  },
  watch: {
    window(test) {
      console.log(test);
    },
    change() {
      if (
        this.res1 !== "" &&
        this.res2 !== "" &&
        this.res3 !== "" &&
        this.res4 !== "" &&
        this.res5 !== "" &&
        this.res6 !== "" &&
        this.res7 !== "" &&
        this.res8 !== "" &&
        this.complete
      ) {
        this.button = true;
      } else {
        this.button = false;
      }
    },
  },
  methods: {
    handleScroll() {
      this.getInitialsPositions();
    },
    handleResize() {
      this.getInitialsPositions();
    },
    getInitialsPositions() {
      this.drop1 = this.getPositions(this.$refs.drop1[0]);
      this.drop2 = this.getPositions(this.$refs.drop2[0]);
      this.drop3 = this.getPositions(this.$refs.drop3[0]);
      this.drop4 = this.getPositions(this.$refs.drop4[0]);
      this.drop5 = this.getPositions(this.$refs.drop5[0]);
      this.drop6 = this.getPositions(this.$refs.drop6[0]);
      this.drop7 = this.getPositions(this.$refs.drop7[0]);
      this.drop8 = this.getPositions(this.$refs.drop8[0]);
    },
    countResult() {
      let count = 0;
      if (this.res1 == "aed-1") {
        count++;
      }
      if (this.res2 == "aed-2") {
        count++;
      }
      if (this.res3 == "aed-3") {
        count++;
      }
      if (this.res4 == "aed-4") {
        count++;
      }
      if (this.res5 == "aed-5") {
        count++;
      }
      if (this.res6 == "aed-6") {
        count++;
      }
      if (this.res7 == "aed-7") {
        count++;
      }
      if (this.res8 == "aed-8") {
        count++;
      }
      this.score = (count / 8) * 100;
      window.removeEventListener("scroll", this.handleScroll);
      window.removeEventListener("resize", this.handleResize);
      document.getElementsByTagName("html")[0].style.overflow = "scroll";
    },
    onPosChanged: function (positionDiff, absolutePosition, e) {
      this.complete = true;
      let drag = [
        [absolutePosition.left, absolutePosition.left + e.target.offsetWidth],
        [absolutePosition.top, absolutePosition.top + e.target.offsetHeight],
      ];
      let horizontalMatch1 = this.comparePositions(drag[0], this.drop1[0]);
      let verticalMatch1 = this.comparePositions(drag[1], this.drop1[1]);
      let horizontalMatch2 = this.comparePositions(drag[0], this.drop2[0]);
      let verticalMatch2 = this.comparePositions(drag[1], this.drop2[1]);
      let horizontalMatch3 = this.comparePositions(drag[0], this.drop3[0]);
      let verticalMatch3 = this.comparePositions(drag[1], this.drop3[1]);
      let horizontalMatch4 = this.comparePositions(drag[0], this.drop4[0]);
      let verticalMatch4 = this.comparePositions(drag[1], this.drop4[1]);
      let horizontalMatch5 = this.comparePositions(drag[0], this.drop5[0]);
      let verticalMatch5 = this.comparePositions(drag[1], this.drop5[1]);
      let horizontalMatch6 = this.comparePositions(drag[0], this.drop6[0]);
      let verticalMatch6 = this.comparePositions(drag[1], this.drop6[1]);
      let horizontalMatch7 = this.comparePositions(drag[0], this.drop7[0]);
      let verticalMatch7 = this.comparePositions(drag[1], this.drop7[1]);
      let horizontalMatch8 = this.comparePositions(drag[0], this.drop8[0]);
      let verticalMatch8 = this.comparePositions(drag[1], this.drop8[1]);

      if (horizontalMatch1 && verticalMatch1) {
        let dropX = this.$refs.drop1[0].getBoundingClientRect().x;
        let dropY = this.$refs.drop1[0].getBoundingClientRect().y;
        let d = document.getElementById(e.target.id);
        d.style.left = dropX + "px";
        d.style.top = dropY + "px";
        this.res1 = e.target.id;
      } else if (horizontalMatch2 && verticalMatch2) {
        let dropX = this.$refs.drop2[0].getBoundingClientRect().x;
        let dropY = this.$refs.drop2[0].getBoundingClientRect().y;
        let d = document.getElementById(e.target.id);
        d.style.left = dropX + "px";
        d.style.top = dropY + "px";
        this.res2 = e.target.id;
      } else if (horizontalMatch3 && verticalMatch3) {
        let dropX = this.$refs.drop3[0].getBoundingClientRect().x;
        let dropY = this.$refs.drop3[0].getBoundingClientRect().y;
        let d = document.getElementById(e.target.id);
        d.style.left = dropX + "px";
        d.style.top = dropY + "px";
        this.res3 = e.target.id;
      } else if (horizontalMatch4 && verticalMatch4) {
        let dropX = this.$refs.drop4[0].getBoundingClientRect().x;
        let dropY = this.$refs.drop4[0].getBoundingClientRect().y;
        let d = document.getElementById(e.target.id);
        d.style.left = dropX + "px";
        d.style.top = dropY + "px";
        this.res4 = e.target.id;
      } else if (horizontalMatch5 && verticalMatch5) {
        let dropX = this.$refs.drop5[0].getBoundingClientRect().x;
        let dropY = this.$refs.drop5[0].getBoundingClientRect().y;
        let d = document.getElementById(e.target.id);
        d.style.left = dropX + "px";
        d.style.top = dropY + "px";
        this.res5 = e.target.id;
      } else if (horizontalMatch6 && verticalMatch6) {
        let dropX = this.$refs.drop6[0].getBoundingClientRect().x;
        let dropY = this.$refs.drop6[0].getBoundingClientRect().y;
        let d = document.getElementById(e.target.id);
        d.style.left = dropX + "px";
        d.style.top = dropY + "px";
        this.res6 = e.target.id;
      } else if (horizontalMatch7 && verticalMatch7) {
        let dropX = this.$refs.drop7[0].getBoundingClientRect().x;
        let dropY = this.$refs.drop7[0].getBoundingClientRect().y;
        let d = document.getElementById(e.target.id);
        d.style.left = dropX + "px";
        d.style.top = dropY + "px";
        this.res7 = e.target.id;
      } else if (horizontalMatch8 && verticalMatch8) {
        let dropX = this.$refs.drop8[0].getBoundingClientRect().x;
        let dropY = this.$refs.drop8[0].getBoundingClientRect().y;
        let d = document.getElementById(e.target.id);
        d.style.left = dropX + "px";
        d.style.top = dropY + "px";
        this.res8 = e.target.id;
      } else {
        this.complete = false;
      }
      console.log("1: ", this.res1);
      console.log("2: ", this.res2);
      console.log("3: ", this.res3);
      console.log("4: ", this.res4);
      console.log("5: ", this.res5);
      console.log("6: ", this.res6);
      console.log("7: ", this.res7);
      console.log("8: ", this.res8);
      this.change++;
    },
    goToVideo() {
      this.$parent.openPractice(
        "video",
        21,
        "16_Co_jsme_se_naucili.mp4",
        '{"relatedVideos": false, "goToPractice": "snake", "practiceId": "29"}',
        true
      );
    },
    reset(practiceId) {
      window.addEventListener("scroll", this.handleScroll);
      window.addEventListener("resize", this.handleResize);
      window.scrollTo(0, 0);
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
      this.$parent.switchPractice("aed", practiceId);
    },
    getPositions(box) {
      let pos = box.getBoundingClientRect();
      let width = box.getBoundingClientRect().width;
      var height = box.getBoundingClientRect().height;
      return [
        [pos.left, pos.left + width],
        [pos.top, pos.top + height],
      ];
    },
    comparePositions(p1, p2) {
      var x1 = p1[0] < p2[0] ? p1 : p2;
      var x2 = p1[0] < p2[0] ? p2 : p1;
      return x1[1] > x2[0] || x1[0] === x2[0] ? true : false;
    },
    toggleDrawer(toggle) {
      EventBus.$emit("TOGGLE_DRAWER", toggle);
    },
  },
};
</script>

<style scoped>
html {
  overflow: hidden !important;
}
#round {
  max-width: 38vw;
}
.aed-drag {
  width: 6vw;
  height: 6vw;
  border-radius: 50%;
  background-size: cover;
  cursor: pointer;
  position: relative;
  z-index: 3;
}
.slot {
  width: 6vw;
  height: 6vw;
  border-radius: 50%;
  background-color: silver;
  cursor: pointer;
  position: relative;
}
.slot div {
  height: 100%;
  text-align: center;
  padding-top: 2vw;
  color: black;
  font-size: 1.8vw;
  font-weight: 800;
}
.slot:hover {
  background-color: white;
}
.slot:nth-of-type(1) {
  left: 10vw;
  top: -33vw;
}
.slot:nth-of-type(2) {
  left: 19vw;
  top: -41vw;
}
.slot:nth-of-type(3) {
  left: 27vw;
  top: -42vw;
}
.slot:nth-of-type(4) {
  left: 29vw;
  top: -39vw;
}
.slot:nth-of-type(5) {
  left: 25vw;
  top: -36vw;
}
.slot:nth-of-type(6) {
  left: 16vw;
  top: -39vw;
}
.slot:nth-of-type(7) {
  left: 7vw;
  top: -49vw;
}
.slot:nth-of-type(8) {
  left: 4vw;
  top: -63vw;
}
#left-drag {
  text-align: -webkit-right;
  text-align: -moz-right;
}
#right-drag {
  text-align: -webkit-left;
  text-align: -moz-left;
}
</style>
