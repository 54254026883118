<template>
  <div v-if="score === null">
    <Bookmark :data="bookmarkData" />
    <v-row align="center" justify="center">
      <v-col cols="10"></v-col>
      <v-col cols="2">
        <div class="base-timer">
          <svg
            class="base-timer__svg"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g class="base-timer__circle">
              <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45" />
              <path
                :stroke-dasharray="circleDasharray"
                class="base-timer__path-remaining"
                :class="remainingPathColor"
                d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
              />
            </g>
          </svg>
          <span class="base-timer__label">{{ formattedTimeLeft }}</span>
        </div>
      </v-col>
    </v-row>
    <v-overlay :absolute="true" :value="overlay"> </v-overlay>
    <v-card v-show="dialog" class="mx-auto" max-width="500">
      <v-card-text align="center">
        <h2>{{ practiceName }}</h2>
        {{ practiceInfo }}
      </v-card-text>
      <v-card-actions align="center">
        <v-row align="center" justify="center">
          <v-btn
            @click="
              dialog = false;
              overlay = false;
            "
            x-large
            color="success"
            >Jdu na to</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
    <v-row align="center" justify="center">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Situace</th>
              <th class="text-left">Bezpečnost</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(list, i) in lists" :key="i">
              <td>
                <strong>{{ list.question }}</strong>
              </td>
              <td>
                <drop
                  :id="'list-' + i"
                  class="drop list"
                  @drop="handleDrop(list.answers, i, ...arguments)"
                >
                  <drag
                    v-for="item in list.answers"
                    @drag="startTest"
                    class="drag"
                    :key="item"
                    :class="{ [item]: true }"
                    :transfer-data="{
                      item: item,
                      list: list.answers,
                      example: 'lists',
                    }"
                    >{{ item }}</drag
                  >
                </drop>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
    <v-row class="complete-button" align="center" justify="center">
      <v-btn v-show="complete" @click="completeTest" x-large color="success">
        <v-icon dark left>mdi-check</v-icon>Vyhodnotit test
      </v-btn>
    </v-row>
  </div>
  <div v-else>
    <Results
      :userId="this.userId"
      :itemId="7"
      :score="this.score"
      :settings="this.resultSettings"
    />
  </div>
</template>

<script>
import { Drag, Drop } from "vue-drag-drop";
import EventBus from "../../../event-bus";
import Results from "../../Tools/Results";
import Bookmark from "../../Tools/Bookmark";
import api from "../../../api/api";

const FULL_DASH_ARRAY = 283;
const WARNING_THRESHOLD = 10;
const ALERT_THRESHOLD = 5;
const COLOR_CODES = {
  info: {
    color: "green",
  },
  warning: {
    color: "orange",
    threshold: WARNING_THRESHOLD,
  },
  alert: {
    color: "red",
    threshold: ALERT_THRESHOLD,
  },
};
export default {
  props: {
    userId: String,
    practiceId: Number,
  },
  components: { Drag, Drop, Results, Bookmark },
  data() {
    return {
      resultSettings: {
        goToVideo: true,
      },
      complete: false,
      dialog: true,
      practiceInfo: null,
      practiceName: null,
      score: null,
      overlay: true,
      timePassed: 0,
      results: [],
      drawer: null,
      timeLimit: 90,
      timerInterval: null,
      timer: 90,
      testStarted: false,
      answers: [
        { content: "kontrola okolí", question: 0 },
        { content: "provaz nebo lano", question: 1 },
        { content: "záchranný kruh", question: 1 },
        { content: "zastavení na bezpečném místě", question: 2 },
        { content: "refexní vesta", question: 2 },
        { content: "otevření okna", question: 3 },
        { content: "odchod z místnosti/bytu", question: 3 },
        { content: "útěk do bezpečí", question: 4 },
        { content: "vypnutí jističe", question: 5 },
      ],
      lists: [
        { question_id: 1, question: "Náhlý kolaps", answers: [] },
        { question_id: 2, question: "Tonutí", answers: [] },
        { question_id: 3, question: "Dopravní nehoda", answers: [] },
        { question_id: 4, question: "Otrava oxidem uhelnatým", answers: [] },
        { question_id: 5, question: "Napadení", answers: [] },
        { question_id: 6, question: "Úraz elektrickým proudem", answers: [] },
        {
          question_id: null,
          question: null,
          answers: [
            "provaz nebo lano",
            "záchranný kruh",
            "kontrola okolí",
            "zastavení na bezpečném místě",
            "otevření okna",
            "refexní vesta",
            "útěk do bezpečí",
            "odchod z místnosti/bytu",
            "vypnutí jističe",
          ],
        },
      ],
    };
  },
  methods: {
    reset(practiceId) {
      this.$parent.switchPractice("draganddroptable", practiceId);
    },
    goToVideo() {
      this.$parent.openPractice(
        "video",
        8,
        "05_Tunelove_videni.mp4",
        '{"relatedVideos": false, "actionOnTime": 33, "practiceOnTimeId": "23", "practiceId": "11"}',
        true
      );
    },
    handleDrop(toList, i, data) {
      const fromList = data.list;
      this.complete = false;
      if (fromList) {
        toList.push(data.item);
        fromList.splice(fromList.indexOf(data.item), 1);
        toList.sort((a, b) => a > b);
        this.isComplete();
      }
    },
    startTest() {
      if (!this.testStarted) {
        this.testStarted = true;
        this.startTimer();
      }

      this.toggleDrawer(false);
    },
    onTimesUp() {
      clearInterval(this.timerInterval);
      this.completeTest();
    },
    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    toggleDrawer(toggle) {
      EventBus.$emit("TOGGLE_DRAWER", toggle);
    },
    dragged(id, data) {
      console.log(id, data);
    },
    isComplete() {
      this.complete = this.lists[6].answers.length == 0;
    },
    completeTest() {
      let count = this.answers.length;
      let right = 0;
      this.answers.forEach((answer) => {
        if (this.lists[answer.question].answers.includes(answer.content)) {
          ++right;
        }
      });
      this.score = Math.round((right / count) * 100);
    },
  },
  mounted() {
    api
      .post("/practice/getPracticeInfo", { practiceId: this.practiceId })
      .then((response) => {
        this.practiceName = response.data.name;
        this.practiceInfo = response.data.describe;
      });
  },
  computed: {
    bookmarkData() {
      return {
        userId: this.userId,
        practiceId: this.practiceId,
        videoTime: null,
      };
    },
    circleDasharray() {
      return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
    },

    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },

    timeLeft() {
      return this.timeLimit - this.timePassed;
    },

    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.timeLimit;
      return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction);
    },

    remainingPathColor() {
      const { alert, warning, info } = COLOR_CODES;

      if (this.timeLeft <= alert.threshold) {
        return alert.color;
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      }
    },
  },
  watch: {
    timeLeft(newValue) {
      this.timer = newValue;
      if (newValue === 0) {
        this.onTimesUp();
      }
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
};
</script>

<style scoped>
.drag {
  display: inline-block;
  padding: 10px;
  background: rgb(219, 213, 213);
  margin: 2px;
  cursor: pointer;
  border-radius: 20px;
}
.drop {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 600px;
  height: 46px;
}
.drop.list {
  border: 1px grey dashed;
}
.complete-button {
  height: 100px;
}
#list-6 {
  height: 150px;
  padding: 8px;
  background-color: rgb(231, 231, 231);
  border: none;
}

table tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: white;
}
</style>
<style scoped lang="scss">
.base-timer {
  position: relative;
  width: 150px;
  height: 150px;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }

  &__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: rgb(65, 184, 131);
    }

    &.orange {
      color: orange;
    }

    &.red {
      color: red;
    }
  }

  &__label {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
  }
}
@media only screen and (max-width: 1300px) {
  .drop {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 5px;
    margin-top: 5px;
    width: 600px;
    height: 36px;
  }
  .drag {
    display: inline-block;
    padding: 6px;
    background: rgb(219, 213, 213);
    margin: 2px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 12px;
  }
  #list-6 {
    height: 90px;
    padding: 8px;
    background-color: rgb(231, 231, 231);
    border: none;
  }
}
</style>
