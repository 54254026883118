<template>
  <div>
    <div v-if="url === null">Nahrává se video...</div>
    <div else>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="400">
          <v-card>
            <v-card-title class="headline">Odpověď je:</v-card-title>
            <v-card-text>
              <v-text-field
                :rules="['Required']"
                v-model="answerNumber"
                label="Číslo"
                type="number"
              ></v-text-field>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn x-large color="success" @click="showResult">Odeslat</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogResult" persistent max-width="400">
          <v-card class="mx-auto" max-width="500">
            <v-card-text align="center">
              <v-icon class="result-icon" :color="this.result | scoreColor">{{
                this.result | scoreIcon
              }}</v-icon>
              <div class="title" v-if="this.result == 100">
                Skvěle! To je správná odpověď.
              </div>
              <div class="title" v-else>
                Těsně vedle! Správná odpověď je 13.
              </div>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn x-large color="success" @click="closeResult"
                >Pokračovat</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="10" align="center" justify="center">
          <h1>{{ this.videoName }}</h1>
          <v-card max-width="900">
            <video
              id="videoElement"
              onloadstart="this.volume=0.5"
              @canplay="updatePaused"
              @playing="updatePaused"
              @pause="updatePaused"
              @timeupdate="videoTimeUpdated"
              @ended="endAction"
              autoplay
              width="100%"
              :src="url"
              oncontextmenu="return false;"
              controlslist="nodownload"
            ></video>
            <v-divider></v-divider>

            <div class="video-controls">
              <v-row class="progress-bar">
                <template>
                  <v-progress-linear
                    align="left"
                    color="red darken-3"
                    v-model="progressBar"
                    height="25"
                    reactive
                    value="20"
                  >
                    <strong
                      >{{ currentTime | time }} /
                      {{ videoDuration | time }}</strong
                    >
                  </v-progress-linear>
                </template>
              </v-row>
              <v-row align="center" justify="center">
                <v-col>
                  <v-btn @click="openFullscreen" color="blue">
                    <v-icon dark left>mdi-arrow-expand-all</v-icon>Celá
                    obrazovka
                  </v-btn></v-col
                >
                <v-col>
                  <v-btn v-show="paused" @click="play" x-large color="success">
                    <v-icon dark left>mdi-play</v-icon>Spustit video
                  </v-btn>
                  <v-btn v-show="playing" @click="pause" x-large color="amber">
                    <v-icon dark left>mdi-pause</v-icon>Pozastavit video
                  </v-btn>
                </v-col>

                <v-col class="volume-control" align="center" justify="center">
                  <v-slider
                    v-model="videoVolume"
                    tick-size="1"
                    min="0"
                    max="10"
                    prepend-icon="mdi-volume-high "
                  ></v-slider>
                </v-col>
              </v-row>
            </div>
            <v-overlay :absolute="absolute" :value="overlay" opacity="0.9">
              <h2>Další videa</h2>
              <v-card max-width="400" class="mx-auto">
                <v-container class="pa-1">
                  <v-item-group v-model="selected" multiple>
                    <v-row>
                      <v-col
                        v-for="(item, i) in items"
                        :key="i"
                        cols="12"
                        md="6"
                      >
                        <v-item v-slot:default="{ toggle }">
                          <v-img
                            :src="`https://cdn.vuetifyjs.com/images/${item.src}`"
                            height="150"
                            class="text-right pa-2"
                            @click="toggle"
                          ></v-img>
                        </v-item>
                      </v-col>
                      <v-btn
                        class="repeat-button"
                        v-show="playing"
                        @click="pause"
                        x-large
                        color="indigo"
                      >
                        <v-icon dark left>mdi-replay</v-icon>Opakovat video
                      </v-btn>
                    </v-row>
                  </v-item-group>
                </v-container>
              </v-card>
              <v-row align="center" justify="center">
                <v-btn @click="goToDashboard" x-large color="success">
                  Pokračovat
                  <v-icon dark right>mdi-play</v-icon>
                </v-btn>
              </v-row> </v-overlay
            >¨
            <v-overlay
              :absolute="absolute"
              :value="overlayPractice"
              opacity="0.9"
            >
              <v-row align="center" justify="center">
                <v-btn @click="replay" x-large color="success">
                  Opakovat video
                  <v-icon dark right>mdi-replay</v-icon>
                </v-btn>
                <v-btn
                  class="replay-button"
                  @click="goToPractice"
                  x-large
                  color="red darken-3"
                >
                  Pokračovat na cvičení
                  <v-icon dark right>mdi-play</v-icon>
                </v-btn>
              </v-row>
            </v-overlay>
            <v-overlay :absolute="absolute" :value="router10" opacity="0.9">
              <v-row align="center" justify="center">
                <v-btn
                  class="replay-button"
                  @click="replay"
                  x-large
                  color="success"
                >
                  Opakovat video
                  <v-icon dark right>mdi-replay</v-icon>
                </v-btn>
                <v-btn
                  class="replay-button"
                  @click="goToPractice"
                  x-large
                  color="red darken-3"
                >
                  Pokračovat na cvičení
                  <v-icon dark right>mdi-play</v-icon>
                </v-btn>
              </v-row>
            </v-overlay>
            <v-overlay :absolute="absolute" :value="router" opacity="0.9">
              <v-row align="center" justify="center">
                <v-btn
                  class="replay-button"
                  @click="replay"
                  x-large
                  color="success"
                >
                  Opakovat video
                  <v-icon dark right>mdi-replay</v-icon>
                </v-btn>
                <v-btn
                  class="replay-button"
                  @click="goToPractice"
                  x-large
                  color="red darken-3"
                >
                  Pokračovat na cvičení
                  <v-icon dark right>mdi-play</v-icon>
                </v-btn>
                <v-btn
                  class="replay-button"
                  @click="switchVideo"
                  x-large
                  color="light-blue darken-4"
                >
                  Pokračovat na bonusové video "Dopravní nehoda"
                  <v-icon dark right>mdi-play</v-icon>
                </v-btn>
              </v-row>
            </v-overlay>
            <v-overlay :absolute="absolute" :value="callRouter" opacity="0.9">
              <v-row align="center" justify="center">
                <v-btn
                  class="replay-button"
                  @click="replay"
                  x-large
                  color="success"
                >
                  <v-icon dark left>mdi-replay</v-icon>Opakovat video
                </v-btn>
                <v-btn
                  v-show="call1"
                  class="replay-button"
                  @click="switchVideo2"
                  x-large
                  color="light-blue darken-4"
                >
                  Přejít na bonusový video rozhovor o Aplikaci Záchranka
                  <v-icon dark right>mdi-play</v-icon>
                </v-btn>
                <v-btn
                  v-show="call2"
                  class="replay-button"
                  @click="switchVideo1"
                  x-large
                  color="light-blue darken-4"
                >
                  Přejít na bonusové video "Dispečink"
                  <v-icon dark right>mdi-play</v-icon>
                </v-btn>
                <v-btn
                  class="replay-button"
                  @click="goToPracticeTable"
                  x-large
                  color="red darken-3"
                >
                  Pokračovat na cvičení
                  <v-icon dark right>mdi-play</v-icon>
                </v-btn>
              </v-row>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <Bookmark @click="getBookmarkData" :data="bookmarkData" />
  </div>
</template>

<script>
import api from "../../../api/api";
import EventBus from "../../../event-bus";
import Bookmark from "../../Tools/Bookmark";
import * as Sentry from "@sentry/browser";

export default {
  components: {
    Bookmark,
  },
  data: () => ({
    bookmarkData: null,
    started: false,
    url: null,
    dialog: false,
    dialogResult: false,
    actionWasShown: false,
    answerNumber: null,
    videoElement: null,
    paused: null,
    videoVolume: 5,
    router: false,
    router10: false,
    callRouter: false,
    result: null,
    currentTime: 0,
    videoDuration: 50,
    overlay: false,
    absolute: true,
    call1: true,
    call2: true,
    call3: true,
    videoName: null,
    progressBar: 0,
    overlayPractice: false,
    nextPractice: null,
    items: [
      {
        src: "backgrounds/bg.jpg",
      },
      {
        src: "backgrounds/md.jpg",
      },
    ],
    selected: [],
  }),
  methods: {
    openFullscreen() {
      var elem = document.getElementById("videoElement");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },
    getBookmarkData() {
      this.bookmarkData = {
        userId: this.userId,
        practiceId: this.practiceId,
        videoTime: Math.round(this.videoElement.currentTime),
      };
    },
    toggleDrawer(toggle) {
      EventBus.$emit("TOGGLE_DRAWER", toggle);
    },
    goToTutorial() {
      this.$router.push({
        name: "tutorial",
        params: {
          showButton: true,
        },
      });
    },
    updatePaused(event) {
      this.videoElement = event.target;
      this.paused = event.target.paused;
      this.videoDuration = Math.round(this.videoElement.duration);
      if (this.startTime !== undefined && !this.started) {
        this.started = true;
        this.videoElement.currentTime = this.startTime;
      } else if (this.settings.startTime !== undefined && !this.started) {
        this.started = true;
        this.videoElement.currentTime = this.settings.startTime;
      }
    },
    play() {
      this.videoElement.play();
      this.toggleDrawer(false);
    },
    videoTimeUpdated() {
      this.getBookmarkData();
      this.currentTime = Math.round(this.videoElement.currentTime);

      this.progressBar =
        (100 / this.videoElement.duration) * this.videoElement.currentTime;

      if (this.settings) {
        if (this.settings.actionOnTime == this.currentTime) {
          this.showQuestion();
        }
      }
      if (this.settings) {
        if (this.settings.practiceOnTime == this.currentTime) {
          this.$parent.openPractice(
            this.settings.practiceType,
            this.settings.practiceOnTimeId,
            null,
            null,
            true
          );
        }
      }
    },
    showQuestion() {
      if (!this.actionWasShown) {
        this.exitFullScreen();
        this.videoElement.pause();
        this.videoElement.time = 36;
        this.dialog = true;
        this.actionWasShown = true;
      }
    },
    replay() {
      this.currentTime = 0;
      this.play();
      this.overlay = false;
      this.overlayPractice = false;
      this.callRouter = false;
      this.router = false;
    },
    showResult() {
      this.dialog = false;
      this.dialogResult = true;
    },
    closeResult() {
      this.dialogResult = false;

      this.videoElement.time = 36;
      this.play();
    },
    pause() {
      this.videoElement.pause();
      this.toggleDrawer(true);
    },
    volume() {
      this.videoElement.volume = this.videoVolume;
    },
    endAction() {
      this.exitFullScreen();
      this.toggleDrawer(true);
      api.post("/practice/saveResult", {
        user_id: this.userId,
        course_item_id: this.practiceId,
        result: 100,
      });

      if (this.settings.relatedVideos) {
        this.showNext();
      } else if (this.settings.goToPractice) {
        this.nextPractice = this.settings.goToPractice;
        this.showNextPractice();
      } else if (this.practiceId === 1) {
        this.goToTutorial();
      } else if (this.practiceId == 2) {
        this.switchVideo4();
      } else if (this.practiceId == 12) {
        this.showCallDialog(0);
        this.nextPractice = "table";
        this.settings.practiceId = 32;
      } else if (this.practiceId == 13) {
        this.showCallDialog(1);
        this.nextPractice = "table";
        this.settings.practiceId = 32;
      } else if (this.practiceId == 14) {
        this.showCallDialog(2);
        this.nextPractice = "table";
        this.settings.practiceId = 32;
      } else if (this.practiceId == 8) {
        this.switchVideo3();
        this.nextPractice = "video";
      } else if (this.settings.relatedVideo && this.settings.relatedPractice) {
        this.nextPractice = this.settings.relatedPractice;
        this.router = true;
      } else {
        this.goToDashboard();
      }
    },
    showNext() {
      this.overlay = true;
    },
    showNextPractice() {
      this.overlayPractice = true;
    },
    fullScreen() {
      this.videoElement.requestFullscreen = true;
    },
    setNewTime() {
      this.videoElement.currentTime = Math.round(10);
    },
    nextVideo() {
      this.practiceId = 9;
      this.settings = {
        relatedVideo: 1,
        relatedPractice: "draganddroptable",
        practiceId: "7",
      };
      this.url = "https://vyvojls.cz/public/movies/03_Kontrola_rizik.mp4";
      this.router = false;
      this.toggleDrawer();
      this.play();
    },

    showCallDialog(switchValue) {
      if (switchValue == 1) {
        this.call1 = true;
        this.call2 = false;
        this.call3 = false;
      } else if (switchValue == 2) {
        this.call1 = false;
        this.call2 = true;
        this.call3 = false;
      } else if (switchValue == 3) {
        this.call1 = false;
        this.call2 = false;
        this.call3 = true;
      }
      this.callRouter = true;
    },
    switchVideo() {
      this.practiceId = 10;
      this.settings = {
        relatedVideos: false,
        goToPractice: "draganddroptable",
        practiceId: "7",
      };
      this.url = "https://vyvojls.cz/public/movies/04_Dopravni_nehoda.mp4";
      this.router = false;
      this.toggleDrawer(false);
      this.play();
    },
    switchVideo4() {
      this.practiceId = 9;
      this.settings = {
        relatedVideo: 1,
        relatedPractice: "draganddroptable",
        practiceId: "7",
      };
      this.url = "https://vyvojls.cz/public/movies/03_Kontrola rizik.mp4";
      this.router = false;
      this.toggleDrawer(false);
      this.play();
    },
    switchVideo1() {
      this.practiceId = 13;
      this.settings = {};
      this.url = "https://vyvojls.cz/public/movies/08_Dispecink.mp4";
      this.callRouter = false;
      this.toggleDrawer(false);
      this.play();
    },
    switchVideo2() {
      this.practiceId = 14;
      this.settings = {};
      this.url = "https://vyvojls.cz/public/movies/09_Aplikace_Zachranka.mp4";
      this.callRouter = false;
      this.toggleDrawer(false);
      this.play();
    },
    switchVideo3() {
      this.practiceId = 11;
      this.settings = {
        relatedVideos: false,
        goToPractice: "truefalse",
        practiceId: "5",
        practiceOnTime: 71,
        practiceOnTimeId: "23",
        practiceType: "draganddroporder",
      };
      this.url = "https://vyvojls.cz/public/movies/06_Krvaceni.mp4";
      this.callRouter = false;
      this.toggleDrawer(false);
      this.play();
    },
    goToDashboard() {
      EventBus.$emit("SWITCH_PRACTICE", "dashboard");
    },
    goToPracticeTable() {
      EventBus.$emit("SWITCH_PRACTICE", "table", 32);
    },
    goToPractice() {
      let nextPracticeId = this.settings.practiceId;
      EventBus.$emit("SWITCH_PRACTICE", this.nextPractice, nextPracticeId);
    },
    exitFullScreen() {
      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
      else if (document.msExitFullscreen) document.msExitFullscreen();
    },
  },

  watch: {
    progressBar(value) {
      if (
        value <
        (100 / this.videoElement.duration) * this.videoElement.currentTime - 1
      ) {
        this.videoElement.currentTime =
          (this.videoElement.duration / 100) * value;
      }
    },
    practiceId: function (val) {
      api
        .post("/practice/getPracticeName", { practice_id: val })
        .then((response) => {
          Sentry.setTag("video.name", response.data.name);
          this.videoName = response.data.name;
        });
    },
    videoVolume: function (val) {
      if (this.videoElement) {
        this.videoElement.volume = val / 10;
      }
    },
    answerNumber: function (val) {
      if (val == 13) {
        this.result = 100;
      } else {
        this.result = 80;
      }
    },
  },
  computed: {
    playing() {
      return !this.paused;
    },
  },
  beforeMount() {
    this.bookmarkData = {
      userId: this.userId,
      practiceId: this.practiceId,
      videoTime: 0,
    };
    this.toggleDrawer(false);
    if (this.settings) {
      if (this.settings.videoTime) {
        this.startTime = this.settings.videoTime;
      }
    }
    api
      .post("/practice/getVideoTime", {
        user_id: this.userId,
        practiceId: this.practiceId,
      })
      .then((response) => {
        this.url = "https://vyvojls.cz/public/movies/" + this.path;
        if (this.startTime === undefined) {
          this.startTime = response.data.videoTime;
        }
      });
    api
      .post("/practice/getPracticeName", { practice_id: this.practiceId })
      .then((response) => {
        Sentry.setTag("video.name", response.data.name);
        this.videoName = response.data.name;
      });
  },
  props: {
    path: String,
    practiceId: Number,
    userId: String,
    settings: Object,
    startTime: Number,
  },
};
</script>
<style scoped>
.video-controls button {
  margin: 20px;
}
.volume-control {
  margin-top: 26px;
  margin-right: 31px;
}

.repeat-button {
  margin-left: 100px;
}

.v-progress-linear__bar,
.v-progress-linear__bar__determinate {
  transition: none;
}

.replay-button {
  margin-top: 50px;
  width: 70%;
}
h1 {
  text-align: center;
  margin-bottom: 15px;
}
</style>