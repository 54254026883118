import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './auth'
import darkTheme from './darkTheme'

Vue.use(Vuex)

export const store = new Vuex.Store({
  namespaced: true,
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ],
  state: {

  },
  mutations: {

  },
  actions: {


  },
  getters: {


  },
  modules: {
    auth,
    darkTheme
  }
})
