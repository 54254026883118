<template>
  <v-container :key="componentKey">
    <div v-if="actual === 'clickonheart'">
      <Clicking :practiceId="this.practiceId" :userId="this.user.email" />
    </div>
    <div v-else-if="actual === 'truefalse'">
      <TrueFalse :practiceId="this.practiceId" :userId="this.user.email" />
    </div>
    <div v-else-if="actual === 'draganddroptable'">
      <DragAndDrop :practiceId="this.practiceId" :userId="this.user.email" />
    </div>
    <div v-else-if="actual === 'puzzle'">
      <Puzzle :practiceId="this.practiceId" :userId="this.user.email" />
    </div>
    <div v-else-if="actual === 'aed'">
      <Aed :practiceId="this.practiceId" :userId="this.user.email" />
    </div>
    <div v-else-if="actual === 'snake'">
      <Snake :practiceId="this.practiceId" :userId="this.user.email" />
    </div>
    <div v-else-if="actual === 'listorder'">
      <ListOrder :practiceId="this.practiceId" :userId="this.user.email" />
    </div>
    <div v-else-if="actual === 'cert'">
      <Cert :practiceId="this.practiceId" :user="this.user" />
    </div>
    <div v-else-if="actual === 'video'">
      <Video
        :path="this.path"
        :settings="this.settings"
        :practiceId="this.practiceId"
        :userId="this.user.email"
        :startTime="this.videoStartTime"
      />
    </div>
    <div v-else-if="actual === 'crossword'">
      <Crossword
        :path="this.path"
        :practiceId="this.practiceId"
        :userId="this.user.email"
      />
    </div>
    <div v-else-if="actual === 'table'">
      <Table
        :path="this.path"
        :practiceId="this.practiceId"
        :userId="this.user.email"
      />
    </div>
    <div v-else-if="actual === 'final'">
      <Final
        :path="this.path"
        :practiceId="this.practiceId"
        :userId="this.user.email"
      />
    </div>
    <div v-else-if="actual === 'feedback'">
      <Feedback
        :path="this.path"
        :practiceId="this.practiceId"
        :userId="this.user.email"
      />
    </div>
    <div v-else-if="actual === 'draganddroporder'">
      <Draganddroporder
        :path="this.path"
        :practiceId="this.practiceId"
        :userId="this.user.email"
      />
    </div>
    <div v-else-if="actual === 'dashboard' || actual === undefined">
      <div v-show="dashboardReady">
        <v-row align="center" justify="center">
          <h1>
            Základní kurz první pomoci a resuscitac<span
              @dblclick="cheat = true"
              >e</span
            >
          </h1>
        </v-row>
        <v-row align="center" justify="center">
          <v-timeline>
            <v-timeline-item
              v-for="item in items"
              :key="String(item)"
              :color="item.available ? item.color : 'grey lighten-1'"
              :icon="item.icon"
              size="42"
              elevation="6"
              :class="
                item.settings.includes('bonusVideo')
                  ? 'bonus-video'
                  : 'timeline-item'
              "
              :left="item.type == 'video' || item.type == 'cert' ? true : false"
              :right="
                item.type != 'video' || item.type != 'cert' ? true : false
              "
              large
            >
              <template v-slot:icon></template>
              <span :class="!item.available ? 'disable' : ''" slot="opposite">
                {{
                  item.settings.includes("bonusVideo")
                    ? "Bonusové video (nepovinné)"
                    : item.typeName
                }}
                {{
                  item.settings.includes("practiceOnTimeId") ? " a cvičení" : ""
                }}
              </span>
              <span
                v-show="cheat"
                @click="
                  openPractice(
                    item.type,
                    item.id,
                    item.path,
                    item.settings,
                    true
                  )
                "
                >Spusť s{{ item.id }} {{ item.type }} {{ item.result }}</span
              >
              <v-card
                @click="
                  openPractice(
                    item.type,
                    item.id,
                    item.path,
                    item.settings,
                    item.available
                  )
                "
                :disabled="item.available ? false : true"
                :class="item.available ? 'active' : ''"
              >
                <v-card-title class="headline">{{ item.name }}</v-card-title>
                <v-card-text>
                  {{ item.info }}
                  <v-row
                    v-if="item.result !== null && item.id != 29"
                    align="center"
                    justify="end"
                  >
                    <v-icon
                      :title="item.result + '%'"
                      class="mr-1"
                      :color="item.result > 74 ? 'green' : 'red'"
                      >{{
                        item.result > 74
                          ? "mdi-check-circle"
                          : "mdi-close-circle"
                      }}</v-icon
                    >
                    <span v-if="item.id !== 33"> {{ item.result }} % </span>
                  </v-row>
                  <v-row
                    v-else-if="item.result !== null && item.id == 29"
                    align="center"
                    justify="end"
                  >
                    <v-icon
                      :title="item.result + 'bodů'"
                      class="mr-1"
                      :color="item.result > 0 ? 'green' : 'red'"
                      >{{
                        item.result > 0
                          ? "mdi-check-circle"
                          : "mdi-close-circle"
                      }}</v-icon
                    >
                    {{ item.result }} bodů
                  </v-row>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-row>
      </div>
      <v-row align="center" justify="center" v-show="!dashboardReady">
        <v-progress-circular indeterminate color="red"></v-progress-circular>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import DragAndDrop from "./DragAndDrop";
import Clicking from "./Clicking";
import TrueFalse from "./Truefalse";
import Crossword from "./Crossword";
import Video from "./Video";
import Puzzle from "./Puzzle";
import Aed from "./Aed";
import Snake from "./Snake";
import Table from "./Table";
import Final from "./Final";
import Cert from "./Cert";
import Feedback from "./Feedback";
import ListOrder from "./ListOrder";
import Draganddroporder from "./Draganddroporder";
import api from "../../../api/api";
import { mapGetters } from "vuex";
import EventBus from "../../../event-bus";
import * as Sentry from "@sentry/browser";

export default {
  components: {
    Clicking,
    Snake,
    Cert,
    TrueFalse,
    DragAndDrop,
    Video,
    Crossword,
    Puzzle,
    Table,
    Feedback,
    Aed,
    Draganddroporder,
    ListOrder,
    Final,
  },
  props: {
    actual: {
      type: String,
      default: "dashboard",
    },
    path: String,
    settings: Object,
    practiceId: Number,
    videoStartTime: Number,
  },
  data() {
    return {
      switch: 1,
      items: null,
      dashboardReady: false,
      componentKey: 0,
      cheat: false,
    };
  },
  created() {
    api
      .post("practice/getCourseItems", {
        userId: this.user.email,
        courseId: 1,
      })
      .then((response) => {
        this.items = response.data;
        this.dashboardReady = true;
      });
  },
  methods: {
    openPractice(practiceType, practiceId, path, settings, available) {
      if (practiceType === "tutorial") {
        this.$router.push({
          name: "tutorial",
          params: {
            showButton: false,
          },
        });
      } else {
        if (available) {
          this.practiceId = practiceId;
          if (path !== null) {
            this.path = path;
          }
          if (settings !== null) {
            settings = JSON.parse(settings);
            this.settings = settings;
          }
          this.actual = practiceType;
        }
      }
    },
    switchPractice(practice, practiceId = null) {
      this.switch++;
      this.componentKey += 1;
      this.actual = practice;
      if (practiceId != null) {
        this.practiceId = practiceId;
      }
    },
    addBookmark() {},
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  mounted() {
    EventBus.$on("SWITCH_PRACTICE", (payload, practiceId = null) => {
      this.switchPractice(payload, practiceId);
    });
  },
  watch: {
    switch() {
      api
        .post("practice/getCourseItems", {
          userId: this.user.email,
          courseId: 1,
        })
        .then((response) => {
          this.items = response.data;
          this.dashboardReady = true;
        });
    },
    actual() {
      Sentry.setTag("practice.type", this.actual);
      Sentry.setTag("practice.id", this.practiceId);

      api
        .post("practice/getCourseItems", {
          userId: this.user.email,
          courseId: 1,
        })
        .then((response) => {
          this.items = response.data;
          this.dashboardReady = true;
        });
    },
  },
};
</script>

<style scoped>
.active {
  cursor: pointer;
}
.disable {
  color: #bdbdbd;
}

.v-application .headline {
  font-size: 22px !important;
  line-height: 20px !important;
}

.bonus-video .headline {
  font-size: 17px !important;
  line-height: 10px !important;
}

.v-timeline {
  width: 85%;
}
.v-sheet:hover {
  transform: scale(1.05);
}
</style>
<style>
.mx-auto h2 {
  margin-bottom: 10px;
}
.mx-auto {
  z-index: 2000;
  min-width: 500px;
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */

  transform: translate(
    -50%,
    -50%
  ); /* This is a shorthand of
           
                                         translateX(-50%) and translateY(-50%) */
}

.v-tooltbar {
  z-index: 3000;
}
</style>