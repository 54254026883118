var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"id":"score","align":"center","justify":"center"}},[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('td',[_vm._v("Vaše skóre:")]),_c('td',{staticClass:"points",staticStyle:{"color":"green"}},[_vm._v(_vm._s(_vm.points))]),_c('td',[_vm._v("Vaše nejvyšší skóre:")]),_c('td',{staticClass:"points"},[_vm._v(_vm._s(_vm.userHighscore))]),_c('td',[_vm._v("Celkové nejvyšší skóre:")]),_c('td',{staticClass:"points",staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.highScore))])])])])],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('div',{ref:"gameContainer",attrs:{"id":"container"}},[_c('v-overlay',{attrs:{"absolute":true,"value":_vm.overlay}}),(_vm.info)?_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"500"}},[_c('v-card-text',{attrs:{"align":"center"}},[_c('h2',[_vm._v(_vm._s(_vm.practiceName))]),_vm._v(" "+_vm._s(_vm.practiceInfo)+" ")]),_c('v-card-actions',{attrs:{"align":"center"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-btn',{attrs:{"x-large":"","color":"success"},on:{"click":function($event){_vm.play();
                _vm.overlay = false;
                _vm.info = false;}}},[_vm._v("Jdu na to")])],1)],1)],1):_vm._e(),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.gameover),expression:"gameover"}],staticClass:"mx-auto",attrs:{"max-width":"500"}},[_c('v-card-text',{attrs:{"align":"center"}},[_vm._v("Zkus zachránit ještě víc pacientů!")]),_c('v-card-actions',{attrs:{"align":"center"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-btn',{attrs:{"x-large":"","color":"amber"},on:{"click":_vm.playAgain}},[_vm._v("Hrát znovu")])],1),(_vm.countRound > 2 && _vm.userHighscore > 0)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-btn',{attrs:{"x-large":"","color":"success"},on:{"click":_vm.goToPractice}},[_vm._v("Pokračovat")])],1):_vm._e()],1)],1),_c('div',{ref:"snake",style:({
          top: _vm.verticalPosition + 'px',
          left: _vm.horizontalPosition + 'px',
          backgroundImage: 'url(' + _vm.ambulanceUrl + ')',
          backgroundSize: _vm.ambulanceBackgroundSize,
        }),attrs:{"id":"snake"}}),_c('div',{ref:"wallVerical",attrs:{"id":"wall-vertical"}}),_c('div',{ref:"wallHorizontal",attrs:{"id":"wall-horizontal"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPatient),expression:"showPatient"}],ref:"patient",style:({
          top: _vm.patientTop + 'px',
          left: _vm.patientLeft + 'px',
          backgroundImage: 'url(' + _vm.patientUrl + ')',
        }),attrs:{"id":"patient"}}),_c('div',{ref:"hospital",style:({
          top: '300px',
          left: '500px',
          backgroundImage: 'url(' + _vm.hospitalUrl + ')',
        }),attrs:{"id":"hospital"}}),_c('div',{ref:"car1",style:({
          left: _vm.carPosition + 'px',
          backgroundImage: 'url(' + _vm.carUrl + ')',
        }),attrs:{"id":"car-1"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }