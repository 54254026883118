<template>
  <v-row style="height: 70vw; marging-left: 2vw;">
    <a href="/aktualni-kurz">
      <v-row style="height: 21vw">
        <div class="book-container">
          <div class="book">
            <span class="page turn"><img id="round" v-bind:src="book1" /></span>
            <span class="page turn"><img id="round" v-bind:src="book4" /></span>
            <span class="page turn"><img id="round" v-bind:src="book3" /></span>
            <span class="page turn"><img id="round" v-bind:src="book2" /></span>
            <span class="page turn"><img id="round" v-bind:src="book5" /></span>
            <span class="page turn"><img id="round" v-bind:src="book6" /></span>
            <span class="cover"></span>
            <span class="page"></span>
            <span class="cover turn"></span>
          </div>
        </div>
      </v-row>
      <v-row>
        <div class="name">Ekurz první pomoci</div>
      </v-row>
    </a>
  </v-row>
</template>
<script>
import book1 from "../../../public/book/ambulance-call.png";
import book2 from "../../../public/book/ambulance-car.png";
import book3 from "../../../public/book/ambulance-helicopter.png";
import book4 from "../../../public/book/bandage-finger-bleed.png";
import book5 from "../../../public/book/bandage-leg-hanging.png";
import book6 from "../../../public/book/bandage-shoulder.png";
export default {
  data() {
    return {
      book1: book1,
      book2: book2,
      book3: book3,
      book4: book4,
      book5: book5,
      book6: book6,
    };
  }
};
</script>

<style scoped>
.book {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 9vw;
  height: 0vw;
  perspective: 70rem;
}
.cover {
  background-color: #c62828;
  transform: rotateY(0deg);
  width: 7.25vw;
  height: 10.5vw;
}
.page {
  top: 0.25rem;
  left: 0.25rem;
  background-color: #e9e6c4;
  transform: rotateY(0deg);
  width: 7vw;
  height: 10vw;
  text-align: right;
  font-size: 8px;
  color: #777;
  font-family: monospace;
}
.page::before,
.page::after {
  display: block;
  border-top: 1px dashed rgba(0, 0, 0, 0.3);
  content: "";
  padding-bottom: 1rem;
}
.cover,
.page {
  position: absolute;
  padding: 1rem;
  transform-origin: 100% 0;
  border-radius: 5px 0 0 5px;
  box-shadow: inset 3px 0px 20px rgba(0, 0, 0, 0.2),
    0px 0px 15px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
.cover.turn {
  animation: bookCover 3s forwards;
}
.page.turn {
  animation: bookOpen 3s forwards;
}
.page:nth-of-type(1) {
  animation-delay: 0.05s;
}
.page:nth-of-type(2) {
  animation-delay: 0.33s;
}
.page:nth-of-type(3) {
  animation-delay: 0.66s;
}
.page:nth-of-type(4) {
  animation: bookOpen150deg 3s forwards;
  animation-delay: 0.99s;
}
.page:nth-of-type(5) {
  animation: bookOpen30deg 3s forwards;
  animation-delay: 1.2s;
}
.page:nth-of-type(6) {
  animation: bookOpen55deg 3s forwards;
  animation-delay: 1.25s;
}
@keyframes bookOpen {
  30% {
    z-index: 999;
  }
  100% {
    transform: rotateY(180deg);
    z-index: 999;
  }
}
@keyframes bookCover {
  30% {
    z-index: 999;
  }
  100% {
    transform: rotateY(180deg);
    z-index: 1;
  }
}
@keyframes bookOpen150deg {
  30% {
    z-index: 999;
  }
  100% {
    transform: rotateY(150deg);
    z-index: 999;
  }
}
@keyframes bookOpen55deg {
  30% {
    z-index: 999;
  }
  100% {
    transform: rotateY(55deg);
    z-index: 999;
  }
}
@keyframes bookOpen30deg {
  50% {
    z-index: 999;
  }
  100% {
    transform: rotateY(30deg);
    z-index: 999;
  }
}
a {
  color: #c62828 !important;
  font-size: 1.5vw;
  font-weight: 600;
  width: 20vw;
}

.name {
  width: 100%;
  position: relative;
  float: left;
  text-align: center;
  padding-left: 6vw;
}

.book-container {
  width: 100%;
  position: relative;
  float: left;
  height: 17vw;
}
</style>