<template>
  <v-container v-if="score === null">
    <Bookmark :data="bookmarkData" />
     <v-overlay :absolute="true" :value="overlay"> </v-overlay>
    <v-card  v-show="dialog" class="mx-auto" max-width="500">
      <v-card-text align="center">
        <h2>{{ practiceName }}</h2>
        {{ practiceInfo }}
      </v-card-text>
      <v-card-actions align="center">
        <v-row align="center" justify="center">
          <v-btn  @click="dialog = false; overlay = false" x-large color="success">Jdu na to</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-row align="center" justify="center">
      <div class="base-timer">
        <svg
          class="base-timer__svg"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g class="base-timer__circle">
            <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45" />
            <path
              :stroke-dasharray="circleDasharray"
              class="base-timer__path-remaining"
              :class="remainingPathColor"
              d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
            />
          </g>
        </svg>
        <span class="base-timer__label">{{ formattedTimeLeft }}</span>
      </div>
    </v-row>

    <v-row align="center" justify="center">
      <v-btn
        @click="recordClick"
        @mousedown="startClick"
        @mouseleave="stopClick"
        @mouseup="stopClick"
        @touchstart="startClick"
        @touchend="stopClick"
        @touchcancel="stopClick"
        class="mx-2 pulsating"
        fab
        dark
        x-large
        color="white"
      >
        <span class="compress-counter">{{ count }}</span>
        <v-icon color="red darken-3" dark>mdi-cards-heart</v-icon>
      </v-btn>
    </v-row>
    <v-row class="complete-button" align="center" justify="center">
      <v-btn
        :loading="loading2"
        :disabled="loading2"
        v-show="!testStarted"
        @click="startTest"
        x-large
        color="success"
      >
        <v-icon dark left>{{ buttonIcon }}</v-icon>
        {{ buttonText }}
        <template v-slot:loader>
          <span>Připravte se...</span>
        </template>
      </v-btn>
    </v-row>
  </v-container>
  <v-container v-else>
    <Results
      :userId="this.userId"
      :itemId="this.practiceId"
      :score="this.score"
      :settings="this.resultSettings"
    />
  </v-container>
</template>

<script>
import Results from "../../Tools/Results";
import EventBus from "../../../event-bus";
import Bookmark from "../../Tools/Bookmark";
import api from "../../../api/api";

const FULL_DASH_ARRAY = 283;
const WARNING_THRESHOLD = 10;
const ALERT_THRESHOLD = 5;

const COLOR_CODES = {
  info: {
    color: "green",
  },
  warning: {
    color: "orange",
    threshold: WARNING_THRESHOLD,
  },
  alert: {
    color: "red",
    threshold: ALERT_THRESHOLD,
  },
};
export default {
  components: {
    Results,
    Bookmark,
  },
  props: {
    userId: String,
    practiceId: Number,
  },
  data() {
    return {
      resultSettings: {
        goToVideo: true,
      },
      timePassed: 0,
      overlay: true,
      dialog: true,
      results: [],
      drawer: null,
      score: null,
      timeLimit: 30,
      timerInterval: null,
      timer: 30,
      compress: null,
      lastCompress: null,
      count: null,
      testStarted: false,
      interval: false,
      buttonText: "Spustit test",
      buttonIcon: "mdi-check",
      loader: null,
      loading2: false,
      practiceName: null,
      practiceInfo: null
    };
  },
  computed: {
    bookmarkData() {
      return {
        userId: this.userId,
        practiceId: this.practiceId,
        videoTime: null,
      };
    },
    circleDasharray() {
      return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
    },

    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },

    timeLeft() {
      return this.timeLimit - this.timePassed;
    },

    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.timeLimit;
      return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction);
    },

    remainingPathColor() {
      const { alert, warning, info } = COLOR_CODES;

      if (this.timeLeft <= alert.threshold) {
        return alert.color;
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      }
    },
  },

  watch: {
    timeLeft(newValue) {
      this.timer = newValue;
      if (newValue === 0) {
        this.onTimesUp();
      }
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },

  mounted() {
        api
      .post("/practice/getPracticeInfo", { practiceId: this.practiceId })
      .then((response) => {
        this.practiceName = response.data.name;
        this.practiceInfo = response.data.describe;
      });
  },

  methods: {
    goToVideo() {
      this.$parent.openPractice(
        "video",
        19,
        "14_Poraneni_patere.mp4",
        '{"relatedVideos": false, "goToPractice": "truefalse", "practiceId": "26"}',
        true
      );
    },
    reset(practiceId) {
      this.$parent.switchPractice("clickonheart", practiceId);
    },
    getBookmarkData() {
      // this.bookmarkData = {
      //   userId: this.userId,
      //   practiceId: 2,
      //   videoTime: null
      // };
    },
    onTimesUp() {
      clearInterval(this.timerInterval);
      this.getScore();
    },

    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    startTest() {
      this.toggleDrawer(false);
      this.loader = "loading2";
      this.buttonText = "Připravte se";
      this.buttonIcon = "mdi-alert-outline ";
      setTimeout(() => {
        this.testStarted = true;
        this.startTimer();
      }, 3000);
    },
    recordClick() {
      if (this.testStarted) {
        this.results.push(this.timer);
        this.count = this.count + 1;
      }
    },
    startClick() {
      if (!this.interval) {
        this.interval = setInterval(() => this.compress++, 30);
      }
    },
    stopClick() {
      this.lastCompress = this.compress;
      this.compress = 0;

      clearInterval(this.interval);
      this.interval = false;
    },
    getScore() {
      let i;
      let fails = 0;
      let score = this.results.reduce(
        (prev, curr) => ((prev[curr] = ++prev[curr] || 1), prev),
        {}
      );
      for (i = 1; i < this.timeLimit + 1; i++) {
        if (score[i] != 2) {
          if (score[i] === 1 || score[i] === 3) {
            fails += 1;
          } else {
            fails += 2;
          }
        }
      }
      this.score = Math.round(100 - (fails / (this.timeLimit * 2)) * 100);
    },
    toggleDrawer(toggle) {
      EventBus.$emit("TOGGLE_DRAWER", toggle);
    },
  },
};
</script>
<style scoped>
.compress-counter {
  position: absolute;
  color: white;
  z-index: 1;
  font-size: 30px;
}
.v-btn--fab {
  height: 150px !important;
  width: 150px !important;
  margin: 60px;
}
.v-btn--fab.v-size--x-large .v-icon {
  height: 157px;
  font-size: 100px;
  width: 32px;
}
</style>
<style scoped lang="scss">
.base-timer {
  position: relative;
  width: 150px;
  height: 150px;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }

  &__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: rgb(65, 184, 131);
    }

    &.orange {
      color: orange;
    }

    &.red {
      color: red;
    }
  }

  &__label {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
  }
}
</style>
