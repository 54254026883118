export default {
  namespaced: true,
  state: {
    darkTheme: true
  },
  mutations: {
    SET_THEME (state, dark) {
      state.darkTheme = dark
    }
  },
  actions: {
    changeTheme ({ commit }, dark) {
      commit('SET_THEME', dark)
    }
  },
  getters: {
    darkTheme (state) {
      return state.darkTheme
    }
  }
}
