<template>
  <div>
    <v-navigation-drawer v-model="drawer" app clipped>
      <v-list dense>
        <v-list-item link to="/nastaveni">
          <v-list-item-avatar color="red darken-3">
            <span class="white--text headline">{{ initials }}</span>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="title">{{
              user.nice_nicename
            }}</v-list-item-title>
            <v-list-item-subtitle>{{ this.user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title @click.prevent="signOut"
              >Odhlásit</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-subheader class="mt-4 grey--text text--darken-1"
          >UŽIVATELSKÉ MENU</v-subheader
        >
        <div v-for="item in menuItems" :key="item.text">
          <v-list-item
            @click="clickAction(item.text)"
            v-if="!item.listGroup"
            link
            :to="item.path"
          >
            <v-list-item-action>
              <v-badge
                v-if="item.count > 0"
                color="green"
                :content="item.count"
                overlap
              >
                <v-icon>{{ item.icon }}</v-icon>
              </v-badge>
              <v-icon v-if="item.count == 0">{{ item.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-if="item.listGroup"
            :prepend-icon="item.icon"
            :value="true"
            nav="true"
          >
            <template v-slot:activator>
              <v-list-item-title :to="item.link">{{
                item.text
              }}</v-list-item-title>
            </template>

            <v-list-item
              v-for="itemLearning in itemsLearning"
              :key="itemLearning.text"
              link
              :to="itemLearning.path"
              class="a-sub-menu"
            >
              <v-list-item-action>
                <v-badge
                  v-if="itemLearning.count > 0"
                  color="green"
                  :content="itemLearning.count"
                  overlap
                >
                  <v-icon>{{ itemLearning.icon }}</v-icon>
                </v-badge>
                <v-icon v-if="itemLearning.count == 0">{{
                  itemLearning.icon
                }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ itemLearning.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list-group>
        </div>
        <v-divider></v-divider>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app clipped-left color="red darken-3" dense>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-icon class="mx-4" large>mdi-school</v-icon>
      <v-toolbar-title class="mr-12 align-center">
        <span class="main-title">E-learning Life Support</span>
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>
  </div>
</template>

<script>
import api from "../../api/api";
import { mapGetters, mapActions } from "vuex";
import { userToken } from "../../main";
import EventBus from "../../event-bus";

export default {
  data() {
    return {
      menuItems: null,
    };
  },
  created() {
    api
      .get("users/menuItems/" + userToken)
      .then((response) => (this.menuItems = response.data));
  },
  methods: {
    ...mapActions({
      signOutAction: "auth/signOut",
    }),
    clickAction(itemText) {
      if (itemText === "Aktuální kurz") {
        EventBus.$emit("SWITCH_PRACTICE", "dashboard");
      }
    },
    toggleDrawer(payload) {
      this.drawer = payload;
    },
    signOut() {
      this.signOutAction().then(() => {
        this.$router.replace({
          name: "homePage",
        });
      });
    },
    changeTheme() {
      api.post("/users/setSettings", {
        user_id: this.user.email,
        name: "darkTheme",
        value: this.darkTheme,
      });
    },
  },
  mounted() {
    EventBus.$on("TOGGLE_DRAWER", (payload) => {
      this.toggleDrawer(payload);
    });
  },

  props: {
    drawer: Boolean,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      darkTheme: "darkTheme/darkTheme",
    }),
    initials() {
      if (this.user.name !== "" && this.user.surname !== "") {
        return this.user.name[0] + this.user.surname[0];
      } else {
        return "LS";
      }
    },
  },
};
</script>

<style>
.main-title {
  color: white;
}

.v-tooltbar {
  z-index: 3000;
}
</style>