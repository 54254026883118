<template>
  <div class="final-container" v-if="questionIndex < quiz.questions.length">
    <!--container-->
    <section class="container">
    <v-row>
      <v-col cols="10"
        ><div class="mobile-timer">
          Zbývající čas: <b>{{ formattedTimeLeft }}</b>
        </div></v-col
      >
      <v-col cols="2">
        <div class="base-timer">
          <svg
            class="base-timer__svg"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g class="base-timer__circle">
              <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45" />
              <path
                :stroke-dasharray="circleDasharray"
                class="base-timer__path-remaining"
                :class="remainingPathColor"
                d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
              />
            </g>
          </svg>
          <span class="base-timer__label">{{ formattedTimeLeft }}</span>
        </div>
      </v-col>
    </v-row>
      <!--questionBox-->
      <div class="questionBox" id="app">
        <!-- transition -->
        <transition
          :duration="{ enter: 500, leave: 300 }"
          enter-active-class="animated zoomIn"
          leave-active-class="animated zoomOut"
          mode="out-in"
        >
          <!--qusetionContainer-->
          <div
            class="questionContainer"
            v-if="questionIndex < quiz.questions.length"
            v-bind:key="questionIndex"
          >
            <header>
              <h1 class="title is-6">Závěrečný test</h1>
              <!--progress-->
              <div class="progressContainer">
                <progress
                  class="progress is-info is-small"
                  :value="(questionIndex / quiz.questions.length) * 100"
                  max="100"
                >
                  {{ (questionIndex / quiz.questions.length) * 100 }}%
                </progress>
                <p>
                  {{
                    Math.round((questionIndex / quiz.questions.length) * 100)
                  }}% kompletní
                </p>
              </div>
              <!--/progress-->
            </header>

            <!-- questionTitle -->
            <h2 class="titleContainer title">
              {{ quiz.questions[questionIndex].text }}
            </h2>

            <!-- quizOptions -->
            <div class="optionContainer">
              <div
                class="option"
                v-for="(response, index) in quiz.questions[questionIndex]
                  .responses"
                @click="selectOption(index)"
                :class="{
                  'is-selected': userResponses[questionIndex] == index,
                }"
                :key="index"
              >
                {{ index | charIndex }}. {{ response.text }}
              </div>
            </div>

            <!--quizFooter: navigation and progress-->
            <footer class="questionFooter">
              <!--pagination-->
              <nav class="pagination" role="navigation" aria-label="pagination">
                <!-- back button -->
                <v-btn
                  class="button"
                  v-on:click="prev()"
                  :disabled="questionIndex < 1"
                  x-large
                  color="amber"
                  >Zpět</v-btn
                >

                <!-- next button -->

                <v-btn
                  v-if="userResponses[questionIndex] != null"
                  class="button"
                  :class="
                    userResponses[questionIndex] == null ? '' : 'is-active'
                  "
                  v-on:click="next()"
                  :disabled="questionIndex >= quiz.questions.length"
                  x-large
                  color="success"
                  >Další</v-btn
                >
              </nav>
              <!--/pagination-->
            </footer>
            <!--/quizFooter-->
          </div>
          <!--/questionContainer-->

          <!--quizCompletedResult-->
          <div
            v-if="questionIndex >= quiz.questions.length"
            v-bind:key="questionIndex"
            class="quizCompleted has-text-centered"
          >
            <!-- quizCompletedIcon: Achievement Icon -->
            <span class="icon">
              <i
                class="fa"
                :class="
                  score() > 3
                    ? 'fa-check-circle-o is-active'
                    : 'fa-times-circle'
                "
              ></i>
            </span>

            <!--resultTitleBlock-->
            <h2 class="title">
              You did
              {{
                score() > 7 ? "an amazing" : score() > 4 ? "a poor" : "a good"
              }}
              job!
            </h2>
            <p class="subtitle">
              Total score: {{ score() }} / {{ quiz.questions.length }}
            </p>
            <br />
            <a class="button" @click="restart()"
              >restart <i class="fa fa-refresh"></i
            ></a>
            <!--/resultTitleBlock-->
          </div>
          <!--/quizCompetedResult-->
        </transition>
      </div>
      <!--/questionBox-->
    </section>
    <!--/container-->
  </div>
  <div v-else>
    <Results
      :userId="this.userId"
      :itemId="this.practiceId"
      :score="this.score()"
      :settings="this.resultSettings"
    />
  </div>
</template>
<script>
import Vue from "vue";
import Results from "../../Tools/Results";
var quiz = {
    user: "Dave",
    questions: [
      {
        text: "Co je nejdůležitější při poskytování první pomoci?",
        responses: [
          { text: "vlastní bezpečí", correct: true },
          { text: "resuscitace" },
          { text: "masivní krvácení" },
          { text: "volání na tísňovou linku 155" },
        ],
      },
      {
        text:
          "Za masivní krvácení označujeme u dospělého člověka krevní ztrátu nad:",
        responses: [
          { text: "100 ml" },
          { text: "300 ml" },
          { text: "350 ml" },
          { text: "500 ml", correct: true },
        ],
      },
      {
        text: "Mezi základní životní funkce patří:",
        responses: [
          { text: "čich" },
          { text: "dýchání", correct: true },
          { text: "mozek" },
          { text: "krevní tlak" },
        ],
      },
      {
        text: "To, jestli postižený reaguje (je při vědomí) ověříme nejprve:",
        responses: [
          { text: "bolestivým podnětem" },
          {
            text: "oslovením",
            correct: true,
          },
          { text: "fackou" },
          { text: "zatřesením rameny" },
        ],
      },
      {
        text: "Dýchací cesty uvolníme:",
        responses: [
          { text: "přišpendlením jazyka" },
          { text: "narovnáním hlavy" },
          { text: "záklonem hlavy", correct: true },
          { text: "vytažením jazyka" },
        ],
      },
      {
        text: "Dýchání kontrolujeme:",
        responses: [
          { text: "poslechem, rukou na hrudi, pohledem" },
          { text: "pocitem vydechovaného vzduchu a poklepem " },
          {
            text: "pohledem, poslechem, citem na uchu",
            correct: true,
          },
          { text: "pohmatem ruky na hrudníku a poslechem" },
        ],
      },
      {
        text: "Normální dýchání u dospělého člověka je:",
        responses: [
          { text: "nepravidelné" },
          {
            text: "pravidelné, doprovázené zvedáním hrudníku",
            correct: true,
          },
          { text: "20 dechů za minutu" },
          { text: "4 - 5 nádechů za 10 sekund" },
        ],
      },
      {
        text:
          "Název aplikace, která kontaktuje tísňovou linku 155 a zároveň odesílá souřadnice s Vaší polohou?",
        responses: [
          {
            text: "155",
          },
          { text: "ZZS" },
          { text: "Záchranka", correct: true },
          { text: "Sanitka" },
        ],
      },
      {
        text: "Na dálnici můžeme udat svoji polohu podle:",
        responses: [
          { text: "čísel na zvukolamu" },
          { text: "kódu dopravních značek" },
          { text: "kilometrovníku", correct: true },
          { text: "čísla bodu záchrany" },
        ],
      },
      {
        text: "Krvácení primárně stavíme stlačením:",
        responses: [
          { text: "nejbližší žíly" },
          { text: "v místě krvácení", correct: true },
          { text: "nad místem krvácení" },
          { text: "co nejblíže u srdce" },
        ],
      },
      {
        text:
          "Jaký je první krok v případě, že tlakový obvaz v ráně prosakuje?",
        responses: [
          {
            text: "obvaz ponecháme na místě a přidáme další tlakovou vrstvu.",
            correct: true,
          },
          { text: "obvaz sundáme a ránu znovu zavážeme." },
          { text: "zaškrtíme vzdálenější část končetiny." },
          { text: "postiženého vertikalizujeme." },
        ],
      },
      {
        text: "Stlačování hrudníku provádíme:",
        responses: [
          { text: "v horní části hrudní kosti, abychom nezhmoždili srdce" },
          { text: "uprostřed hrudníku, na ploché hrudní kosti", correct: true },
          {
            text:
              "těsně pod hrudní kostí, kde je mnohem snazší hrudník zmáčknout",
          },
          { text: "nalevo od hrudní kosti postiženého, v místě kde je srdce" },
        ],
      },
      {
        text:
          "Pokud je postižený v bezvědomí a po záklonu hlavy normálně, pravidelně dýchá:",
        responses: [
          {
            text:
              "přivoláme záchrannou službu a začneme jej oživovat stlačováním hrudníku",
          },
          { text: "přivoláme záchrannou službu a uložíme jej do polosedu" },
          {
            text:
              "přivoláme záchrannou službu, držíme hlavu v záklonu a kontrolujeme dýchání",
            correct: true,
          },
          { text: "nasypeme mu pod jazyk trochu cukru" },
        ],
      },
      {
        text: "Záklon hlavy u člověka v bezvědomí provádíme proto, abychom:",
        responses: [
          { text: "mu způsobili bolestivý podnět" },
          { text: "zamezili vdechnutí krve nebo zvratků" },
          { text: "lépe viděli do krku" },
          { text: "zprůchodnili dýchací cesty", correct: true },
        ],
      },
      {
        text: "Automatizovaný externí defibrilátor (AED)",
        responses: [
          { text: "smí použít pouze profesionální zdravotník" },
          {
            text:
              "po přinesení k postiženému zapneme a postupujeme podle jeho instrukcí",
            correct: true,
          },
          { text: "lze v případě nutnosti použít i přes oblečení" },
          { text: "není vhodné používat při resuscitaci dospělého" },
        ],
      },
      {
        text: "Po ošetření masivního krvácení zajistíme zraněnému:",
        responses: [
          { text: "léky proti bolesti" },
          { text: "dostatek tekutin" },
          { text: "transport do nemocnice", correct: true },
          { text: "dostatek cukru" },
        ],
      },
      {
        text: "Resuscitaci přerušíme pokud:",
        responses: [
          {
            text: "se postižený probouzí – hýbe se, brání se, otevírá oči",
            correct: true,
          },
          { text: "má postižený křeče" },
          {
            text:
              "již resuscitujeme 2 minuty, abychom znovu zkontrolovali stav pacienta.",
          },
          { text: "byla volána záchranná služba" },
        ],
      },
      {
        text: "Jaké jsou parametry resuscitace?",
        responses: [
          { text: "frekvence stlačení 100-120x za minutu", correct: true },
          { text: "hloubka stlačení hrudníku minimálně 7 cm" },
          { text: "35 stlačení : 3 umělé vdechy" },
          { text: "ruce pod hrudní kostí" },
        ],
      },
      {
        text:
          "Kam se umisťují přístroje AED (automatizovaný externí defibrilátor)?",
        responses: [
          { text: "do všech ambulancí praktických lékařů" },
          {
            text: "na odlehlá místa a místa s vysokou koncentrací lidí",
            correct: true,
          },
          { text: "povinně do každého obchodního domu" },
          { text: "povinně na všechny benzínové pumpy" },
        ],
      },
      {
        text:
          "Se zraněným v bezvědomí s podezřením na poranění páteře, v poloze na břiše:",
        responses: [
          { text: "v žádném případě nemanipulujeme" },
          {
            text:
              "pokud viditelně dýchá, necháme v poloze, ve které se nachází",
            correct: true,
          },
          {
            text:
              "pokud nevíme, jestli dýchá, opatrně otáčíme na záda a kontrolujeme dýchání, ale nikdy nezakláníme hlavu",
          },
          { text: "vždy přetočíme do stabilizované polohy" },
        ],
      },
    ],
  },
  userResponseSkelaton = Array(quiz.questions.length).fill(null);

const FULL_DASH_ARRAY = 283;
const WARNING_THRESHOLD = 10;
const ALERT_THRESHOLD = 5;

const COLOR_CODES = {
  info: {
    color: "green",
  },
  warning: {
    color: "orange",
    threshold: WARNING_THRESHOLD,
  },
  alert: {
    color: "red",
    threshold: ALERT_THRESHOLD,
  },
};
export default {
  props: {
    userId: String,
    practiceId: Number,
  },
  components: {
    Results,
  },
  data() {
    return {
      quiz: quiz,
      timePassed: 0,
      questionIndex: 0,
      userResponses: userResponseSkelaton,
      isActive: false,
      timeLimit: 1800,
      timerInterval: null,
      timer: 1800,
      resultSettings: {
        goToVideo: true,
      },
    };
  },
  filters: {
    charIndex: function (i) {
      return String.fromCharCode(97 + i);
    },
  },
  watch: {
    questionIndex() {
      if (this.questionIndex == quiz.questions.length) {
        console.log("Test komplet");
      }
    },
    timeLeft(newValue) {
      this.timer = newValue;
      if (newValue === 0) {
        this.onTimesUp();
      }
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  computed: {
    circleDasharray() {
      return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
    },

    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },

    timeLeft() {
      return this.timeLimit - this.timePassed;
    },

    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.timeLimit;
      return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction);
    },

    remainingPathColor() {
      const { alert, warning, info } = COLOR_CODES;

      if (this.timeLeft <= alert.threshold) {
        return alert.color;
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      }
    },
  },
  methods: {
    goToVideo() {
      this.$parent.openPractice("cert", 34, "", "{}", true);
    },
    restart: function () {
      this.questionIndex = 0;
      this.userResponses = Array(this.quiz.questions.length).fill(null);
    },
    reset(practiceId) {
      console.log(practiceId);
      this.timeLimit=720;
      this.startTimer();
      this.questionIndex = 0;
      this.userResponses = Array(this.quiz.questions.length).fill(null);
    },
    selectOption: function (index) {
      Vue.set(this.userResponses, this.questionIndex, index);
      //console.log(this.userResponses);
    },
    next: function () {
      if (this.questionIndex < this.quiz.questions.length) this.questionIndex++;
    },

    prev: function () {
      if (this.quiz.questions.length > 0) this.questionIndex--;
    },
    onTimesUp() {
      clearInterval(this.timerInterval);
      this.questionIndex = this.quiz.questions.lengt
    },
    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    // Return "true" count in userResponses
    score: function () {
      var score = 0;
      for (let i = 0; i < this.userResponses.length; i++) {
        if (
          typeof this.quiz.questions[i].responses[this.userResponses[i]] !==
            "undefined" &&
          this.quiz.questions[i].responses[this.userResponses[i]].correct
        ) {
          score = score + 1;
        }
      }
      return (score / this.quiz.questions.length) * 100;

      //return this.userResponses.filter(function(val) { return val }).length;
    },
  },
  mounted() {
    this.startTimer();
  },
};
</script>
<style lang="scss" scoped>
body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  height: 100vh;
  background: #cfd8dc;
  /* mocking native UI */
  cursor: default !important;
  /* remove text selection cursor */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* remove text selection */
  user-drag: none;
  /* disbale element dragging */
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.button {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.title,
.subtitle {
  font-family: Montserrat, sans-serif;
  font-weight: normal;
}

.animated {
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
}

.container {
  margin: 0 0.5rem;
}

.questionBox {
  min-height: 30rem;
  background: #fafafa;
  position: relative;
  display: -webkit-box;
  display: flex;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.questionBox header {
  background: rgba(0, 0, 0, 0.025);
  padding: 1.5rem;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.questionBox header h1 {
  font-weight: bold;
  margin-bottom: 1rem !important;
}
.questionBox header .progressContainer {
  width: 60%;
  margin: 0 auto;
}
.questionBox header .progressContainer > progress {
  margin: 0;
  border-radius: 5rem;
  overflow: hidden;
  border: none;
  color: #3d5afe;
}
.questionBox header .progressContainer > progress::-moz-progress-bar {
  background: #c62828;
}
.questionBox header .progressContainer > progress::-webkit-progress-value {
  background: #c62828;
}
.questionBox header .progressContainer > p {
  margin: 0;
  margin-top: 0.5rem;
}
.questionBox .titleContainer {
  text-align: center;
  margin: 0 auto;
  padding: 1.5rem;
}
.questionBox .quizForm {
  display: block;
  white-space: normal;
  height: 100%;
  width: 100%;
}
.questionBox .quizForm .quizFormContainer {
  height: 100%;
  margin: 15px 18px;
}
.questionBox .quizForm .quizFormContainer .field-label {
  text-align: left;
  margin-bottom: 0.5rem;
}
.questionBox .quizCompleted {
  width: 100%;
  padding: 1rem;
  text-align: center;
}
.questionBox .quizCompleted > .icon {
  color: #ff5252;
  font-size: 5rem;
}
.questionBox .quizCompleted > .icon .is-active {
  color: #00e676;
}
.questionBox .questionContainer {
  white-space: normal;
  height: 100%;
  width: 100%;
}
.questionBox .questionContainer .optionContainer {
  margin-top: 12px;
  -webkit-box-flex: 1;
  flex-grow: 1;
}
.questionBox .questionContainer .optionContainer .option {
  border-radius: 290486px;
  padding: 9px 18px;
  margin: 0 18px;
  margin-bottom: 12px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.85);
  border: transparent 1px solid;
}
.questionBox .questionContainer .optionContainer .option.is-selected {
  border-color: rgba(0, 0, 0, 0.25);
  background-color: white;
}
.questionBox .questionContainer .optionContainer .option:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.questionBox .questionContainer .optionContainer .option:active {
  -webkit-transform: scaleX(0.9);
  transform: scaleX(0.9);
}
.questionBox .questionContainer .questionFooter {
  background: rgba(0, 0, 0, 0.025);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  align-self: flex-end;
}
.questionBox .questionContainer .questionFooter .pagination {
  margin: 15px 25px;
}

.pagination {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.button:hover {
  cursor: pointer;
  background: #eceff1;
  border-color: rgba(0, 0, 0, 0.25);
}
.button.is-active {
  background: #c62828;
  color: white;
  border-color: transparent;
}
.button.is-active:hover {
  background: #c62828;
}

@media screen and (min-width: 769px) {
  .questionBox {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .questionBox .questionContainer {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .sidebar {
    height: auto !important;
    border-radius: 6px 6px 0px 0px;
  }
}
.final-container {
  margin: 0 auto;
}
</style>
<style lang="scss" scoped>
.base-timer {
  position: relative;
  width: 150px;
  height: 150px;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }

  &__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: rgb(65, 184, 131);
    }

    &.orange {
      color: orange;
    }

    &.red {
      color: red;
    }
  }

  &__label {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
  }
}
</style>