<template>
  <div v-if="score === null">
    <Bookmark :data="bookmarkData" />
    <v-row id="timer" align="center" justify="center">
      <v-col cols="10"></v-col>
      <v-col cols="2">
        <div class="base-timer">
          <svg
            class="base-timer__svg"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g class="base-timer__circle">
              <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45" />
              <path
                :stroke-dasharray="circleDasharray"
                class="base-timer__path-remaining"
                :class="remainingPathColor"
                d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
              />
            </g>
          </svg>
          <span class="base-timer__label">{{ formattedTimeLeft }}</span>
        </div>
      </v-col>
    </v-row>
    <v-overlay :absolute="true" :value="overlay"> </v-overlay>
    <v-card v-show="dialog" class="mx-auto" max-width="500">
      <v-card-text align="center">
        <h2>{{ practiceName }}</h2>
        {{ practiceInfo }}
      </v-card-text>
      <v-card-actions align="center">
        <v-row align="center" justify="center">
          <v-btn
            @click="
              dialog = false;
              overlay = false;
              startTest();
            "
            x-large
            color="success"
            >Jdu na to</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
    <v-row id="test" align="center" justify="center">
      <div class="root">
        <SlickList class="list" lockAxis="y" v-model="itemsList">
          <SlickItem
            class="list-item"
            v-for="(item, index) in itemsList"
            :index="index"
            :key="index"
            ><b>{{ index + 1 }}.</b>
            {{ item }}
            <br />
            <span v-show="show[index]" class="react">ŽÁDNÁ REAKCE</span>
            <span v-show="show2[index]" class="react"
              >HRUDNÍK SE ZVEDÁ V PRAVIDELNÝCH INTERVALECH, DÝCHÁNÍ JE ČISTÉ,
              BEZ ZVUKOVÝCH FENOMÉNŮ, DÝCHÁNÍ JE NORMÁLNÍ</span
            >
          </SlickItem>
        </SlickList>
      </div>
    </v-row>
    <v-row class="complete-button" align="center" justify="center">
      <v-btn v-show="complete" @click="completeTest" x-large color="success">
        <v-icon dark left>mdi-check</v-icon>Vyhodnotit test
      </v-btn>
    </v-row>
  </div>
  <div v-else>
    <Results
      :userId="this.userId"
      :itemId="this.practiceId"
      :score="this.score"
      :settings="this.resultSettings"
    />
  </div>
</template>

<script>
import EventBus from "../../../event-bus";
import Results from "../../Tools/Results";
import Bookmark from "../../Tools/Bookmark";
import { SlickItem, SlickList } from "vue-slicksort";
import api from "../../../api/api";

const FULL_DASH_ARRAY = 283;
const WARNING_THRESHOLD = 10;
const ALERT_THRESHOLD = 5;
const COLOR_CODES = {
  info: {
    color: "green",
  },
  warning: {
    color: "orange",
    threshold: WARNING_THRESHOLD,
  },
  alert: {
    color: "red",
    threshold: ALERT_THRESHOLD,
  },
};
export default {
  props: {
    userId: String,
    practiceId: Number,
  },
  components: { Results, Bookmark, SlickItem, SlickList },
  data() {
    return {
      resultSettings: {
        goToVideo: true,
      },
      complete: true,
      overlay: true,
      score: null,
      timePassed: 0,
      results: [],
      drawer: null,
      timeLimit: 180,
      timerInterval: null,
      timer: 90,
      testStarted: false,
      itemsList: [],
      dialog: true,
      practiceName: null,
      practiceInfo: null,
      correct: [
        "Bezpečný přístup",
        "Řešení masivního krvácení",
        "Hlasité oslovení „haló jste v pořádku?“",
        "Zatřesení rameny a oslovení „haló…“",
        "Záklon hlavy",
        "Kontrola dýchání pohledem, poslechem a pocitem",
        "Volání o pomoc a na tísňovou linku 155 (i přes aplikaci Záchranka)",
        "Držení hlavy v záklonu do příjezdu ZZS a pravidelná kontrola dýchání",
      ],
      show: [false, false, false, false, false, false, false, false],
      show2: [false, false, false, false, false, false, false, false],
    };
  },
  methods: {
    goToVideo() {
      this.$parent.openPractice(
        "video",
        18,
        "13_Komprese_hrudniku.mp4",
        '{"relatedVideos": false, "goToPractice": "clickonheart", "practiceId": "3"}',
        true
      );
    },
    reset(practiceId) {
      this.$parent.switchPractice("listorder", practiceId);
    },
    startTest() {
      if (!this.testStarted) {
        this.testStarted = true;
        this.startTimer();
      }

      this.toggleDrawer(false);
    },
    onTimesUp() {
      clearInterval(this.timerInterval);
      this.completeTest();
    },
    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    toggleDrawer(toggle) {
      EventBus.$emit("TOGGLE_DRAWER", toggle);
    },
    completeTest() {
      let right = 0;
      if (JSON.stringify(this.itemsList) == JSON.stringify(this.correct)) {
        this.score = 100;
      } else {
        let i;
        for (i = 0; i < this.correct.length; i++) {
          if (this.correct[i] == this.itemsList[i]) {
            right++;
          }
        }
        this.score = Math.round((right / this.correct.length) * 100);
      }
    },
  },
  computed: {
    bookmarkData() {
      return {
        userId: this.userId,
        practiceId: this.practiceId,
        videoTime: null,
      };
    },
    circleDasharray() {
      return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
    },

    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },

    timeLeft() {
      return this.timeLimit - this.timePassed;
    },

    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.timeLimit;
      return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction);
    },

    remainingPathColor() {
      const { alert, warning, info } = COLOR_CODES;

      if (this.timeLeft <= alert.threshold) {
        return alert.color;
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      }
    },
  },
  mounted() {
    api
      .post("/practice/getPracticeInfo", { practiceId: this.practiceId })
      .then((response) => {
        this.practiceName = response.data.name;
        this.practiceInfo = response.data.describe;
      });
    let items = [
      "Volání o pomoc a na tísňovou linku 155 (i přes aplikaci Záchranka)",
      "Bezpečný přístup",
      "Kontrola dýchání pohledem, poslechem a pocitem",
      "Řešení masivního krvácení",
      "Záklon hlavy",
      "Zatřesení rameny a oslovení „haló…“",
      "Držení hlavy v záklonu do příjezdu ZZS a pravidelná kontrola dýchání",
      "Hlasité oslovení „haló jste v pořádku?“",
    ];
    this.itemsList = items;
  },
  watch: {
    itemsList() {
      if (
 
        this.itemsList[2] == this.correct[2] &&
        this.itemsList[1] == this.correct[1] &&
        this.itemsList[0] == this.correct[0]
      ) {
        this.show[2] = true;
      } else {
        this.show[2] = false;
      }
      if (
        this.itemsList[3] == this.correct[3] &&
        this.itemsList[2] == this.correct[2] &&
        this.itemsList[1] == this.correct[1] &&
        this.itemsList[0] == this.correct[0]
      ) {
        this.show[3] = true;
      } else {
        this.show[3] = false;
      }
      if (
        this.itemsList[5] == this.correct[5] &&
        this.itemsList[4] == this.correct[4] &&
        this.itemsList[3] == this.correct[3] &&
        this.itemsList[2] == this.correct[2] &&
        this.itemsList[1] == this.correct[1] &&
        this.itemsList[0] == this.correct[0]
      ) {
        this.show2[5] = true;
      } else {
        this.show2[5] = false;
      }
    },
    timeLeft(newValue) {
      this.timer = newValue;
      if (newValue === 0) {
        this.onTimesUp();
      }
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
};
</script>
<style>
#timer {
  width: 20%;
  float: right;
  margin-right: 125px;
}
#test {
  float: left;
  width: 70%;
  margin-top: 50px;
  margin-bottom: 50px;
}
</style>
<style scoped>
.complete-button {
  margin-top: 30px;
}
.list {
  margin-right: 100px;
}
.list-item {
  padding: 10px;
  background: rgb(219, 213, 213);
  margin: 2px;
  cursor: pointer;
  border-radius: 20px;
}
</style>
<style scoped lang="scss">
.react {
  color: red;
}
.base-timer {
  position: relative;
  width: 150px;
  height: 150px;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }

  &__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: rgb(65, 184, 131);
    }

    &.orange {
      color: orange;
    }

    &.red {
      color: red;
    }
  }

  &__label {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
  }
}
</style>
