import { render, staticRenderFns } from "./Bookmark.vue?vue&type=template&id=2e11d01f&scoped=true"
import script from "./Bookmark.vue?vue&type=script&lang=js"
export * from "./Bookmark.vue?vue&type=script&lang=js"
import style0 from "./Bookmark.vue?vue&type=style&index=0&id=2e11d01f&prod&scoped=true&lang=css"
import style1 from "./Bookmark.vue?vue&type=style&index=1&id=2e11d01f&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e11d01f",
  null
  
)

export default component.exports