<template>
  <v-container>
    <v-hover v-for="item in items" :key="item.title" v-slot:default="{ hover }">
      <v-card
        :elevation="hover ? 12 : 2"
        width="500"
        class="float-left bookmark"
        @click="goToBookmark(item.itemType, item.item_id, item.path, item.settings, item.videoTime)"
        m="1"
      >
        <v-list two-line subheader>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon :color="item.color" v-text="item.icon"></v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
              <span v-if="item.videoTime">Čas: {{item.videoTime}}. vteřina</span>
              <v-list-item-subtitle v-text="item.createdAt"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon>
                <v-icon color="grey lighten-1">mdi-play</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-hover>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../../api/api";

export default {
  data: () => ({
    items: null
  }),
  created() {
    api
      .post("practice/getBookmarks", { userId: this.user.email })
      .then(response => {
        this.items = response.data;
      });
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  methods: {
    goToBookmark(type, practiceId, path, settings, startTime) {
      if (practiceId === 0) {
        this.$router.push({
          name: "tutorial"
        });
      } else {
        this.$router.push({
          name: "practice",
          params: {
            actual: type,
            practiceId: practiceId,
            path: path,
            settings: settings,
            videoStartTime: startTime
          }
        });
      }
    }
  }
};
</script>
<style scoped>
.bookmark {
  margin: 10px;
  cursor: pointer;
  height: 114px;
}
.v-list-item__title {
  font-size: 20px;
  line-height: 40px;
}
</style>