import MyCourses from "./components/Pages/MyCourses.vue";
import Driving from "./components/Pages/Driving.vue";
import Medical from "./components/Pages/Medical.vue";
import School from "./components/Pages/School.vue";
import Learning from "./components/Pages/Learning.vue";
import Practice from "./components/Pages/Learning/Practice.vue";
import Settings from "./components/Pages/Settings";
import Login from "./components/Main/LoginForm";
import Questions from "./components/Pages/Questions";
import Tutorial from "./components/Pages/Tutorial";
import Bookmarks from "./components/Pages/Bookmarks";
import { store } from "./store/store";

const routes = [
  {
    path: "/",
    component: MyCourses,
    beforeEnter: (to, from, next) => {
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login",
        });
      }

      next({
        name: "practice",
      });
    },
  },

  {
    path: "/prihlaseni",
    name: "login",
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/authenticated"]) {
        return next({
          name: "myCourses",
        });
      }
    },
  },
  { path: "/moje-kurzy", component: MyCourses, name: "myCourses" },
  { path: "/autoskola", component: Driving },
  { path: "/zdravotnicke-zarizeni", component: Medical },
  { path: "/skola", component: School },
  { path: "/vyuka", component: Learning },
  { path: "/dotazy", component: Questions, name: "questions" },
  {
    path: "/aktualni-kurz",
    name: "practice",
    component: Practice,
    props: true,
  },
  {
    path: "/pruvodce-skolenim",
    component: Tutorial,
    name: "tutorial",
    props: true,
  },
  { path: "/nastaveni/", component: Settings },
  { path: "/zalozky/", component: Bookmarks },
];

export default routes;
